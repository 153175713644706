import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    btnAdd: {
        position: 'fixed',
        bottom: theme.spacing(9.5),
        right: theme.spacing(2),
        width: theme.spacing(7),
        height: theme.spacing(7),
        backgroundColor: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff',
        borderRadius: '100%',
        cursor: 'pointer',
        transition: 'all 200ms ease-out',
        fontSize: theme.spacing(7),
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        }
    },
    selectField: {
        width: '100%',
        textAlign: 'left',
    },
    item: {
        boxShadow: "-2px 2px 10px rgba(0, 0, 0, 0.15);",
        borderRadius: 10,        
    },
    dragging: {
        backgroundColor: '#DEE5E9'
    },
    icon: {
        display: 'block',
    },
    trash: {
        cursor: 'pointer',
        transition: 'all 200ms ease-out',
        '&:hover': {
            filter: "brightness(0) saturate(100%) invert(12%) sepia(97%) saturate(4208%) hue-rotate(357deg) brightness(92%) contrast(119%)"
        }
    }
}))

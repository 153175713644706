import { destinationService } from 'stores/destination/destination.service'
import { destinationConstants } from 'stores/destination/destination.constants'
import { alertActions } from 'stores/alert/alert.actions'
import { _ } from 'helpers/lang'

export const destinationActions = {
    list,
    show,
    create,
    update,
}

function list(filters = undefined) {
    return dispatch => {
        dispatch(request(filters))
        return destinationService.list(filters).then(
            data => {
                dispatch(success(data))
                return true
            },
            error => {
                dispatch(failure(error))
                return false
            }
        )
    }

    function request(filters) {
        return { type: destinationConstants.LIST_PENDING, filters }
    }

    function success(payload) {
        return { type: destinationConstants.LIST_SUCCESS, payload }
    }

    function failure(error) {
        return { type: destinationConstants.LIST_FAILURE, error }
    }
}

function show(id) {
    return dispatch => {
        dispatch(request(id))
        return destinationService.show(id).then(
            payload => {
                dispatch(success(payload))
                return true
            },
            error => {
                dispatch(alertActions.error(error))
                dispatch(failure(error))
                return false
            }
        )
    }

    function request(id) {
        return { type: destinationConstants.SHOW_PENDING, id }
    }

    function success(payload) {
        return { type: destinationConstants.SHOW_SUCCESS, payload }
    }

    function failure(error) {
        return { type: destinationConstants.SHOW_FAILURE, error }
    }
}

function create(destination) {
    return dispatch => {
        dispatch(request(destination))
        return destinationService.create(destination).then(
            payload => {
                dispatch(alertActions.success(_('alert.createSuccess')))
                dispatch(success(payload))
                return true
            },
            error => {
                dispatch(alertActions.error(error))
                dispatch(failure(error))
                return false
            }
        )
    }

    function request(destination) {
        return { type: destinationConstants.CREATE_PENDING, destination }
    }

    function success(payload) {
        return { type: destinationConstants.CREATE_SUCCESS, payload }
    }

    function failure(error) {
        return { type: destinationConstants.CREATE_FAILURE, error }
    }
}

function update(destination) {
    return dispatch => {
        dispatch(request(destination))
        return destinationService.update(destination).then(
            payload => {
                dispatch(alertActions.success(_('alert.updateSuccess')))
                dispatch(success(payload))
                return true
            },
            error => {
                dispatch(alertActions.error(error))
                dispatch(failure(error))
                return false
            }
        )
    }

    function request(destination) {
        return { type: destinationConstants.UPDATE_PENDING, destination }
    }

    function success(payload) {
        return { type: destinationConstants.UPDATE_SUCCESS, payload }
    }

    function failure(error) {
        return { type: destinationConstants.UPDATE_FAILURE, error }
    }
}
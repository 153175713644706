import React from 'react'
import clsx from 'clsx'
import { detect } from 'detect-browser'
import { useHistory } from 'react-router-dom'

import Button from 'components/Button/Button'
import { useStyles } from './Install.styles'
import PwaContext from 'helpers/PwaContext'
import RouteEnum from 'constants/RouteEnum'

function Install({ className }) {
    const classes = useStyles()  
    const history = useHistory()
    const state = React.useContext(PwaContext)
    const browser = detect()
    const browserList = ['safari','chromium-webview','facebook','ios','crios']
    const browserSupported = (browser && typeof browser === 'object') && !browserList.find(element => element === browser.name)    

    const onClickInstall = async () => {
        if(!browserSupported) {
            history.push(RouteEnum.HELP)
        }else{
            const didInstall = await state.promptInstall()        

            if (didInstall) {
                // User accepted PWA install
            }
        }        
    }

    if (!state.showInstallPrompt && browserSupported) return null

    return (
        <Button
            className={clsx(
                'large',
                classes.installButton,
                className,
            )}
            onClick={onClickInstall}
        >
            Installer l'application
        </Button>
    )
}

export default Install

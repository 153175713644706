import PropTypes from 'prop-types'

const StampType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    date: PropTypes.date
})

export const DestinationRouteType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.number,
    stamps: PropTypes.arrayOf(StampType)
})

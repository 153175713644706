import React from 'react'
import usePWA from 'react-pwa-install-prompt'
import PwaContext from './PwaContext'

function PwaProvider(props) {

    const { promptInstall, isStandalone, isInstallPromptSupported } = usePWA()

    const showInstallPrompt = isInstallPromptSupported && !isStandalone

    return (
        <PwaContext.Provider value={{showInstallPrompt, promptInstall}}>
            {props.children}
        </PwaContext.Provider>
    )
}

export default PwaProvider
export const destinationConstants = {
    LIST_PENDING: 'DESTINATION/LIST_PENDING',
    LIST_SUCCESS: 'DESTINATION/LIST_SUCCESS',
    LIST_FAILURE: 'DESTINATION/LIST_FAILURE',

    SHOW_PENDING: 'DESTINATION/SHOW_PENDING',
    SHOW_SUCCESS: 'DESTINATION/SHOW_SUCCESS',
    SHOW_FAILURE: 'DESTINATION/SHOW_FAILURE',
    
    /*CREATE_PENDING: 'DESTINATION/CREATE_PENDING',
    CREATE_SUCCESS: 'DESTINATION/CREATE_SUCCESS',
    CREATE_FAILURE: 'DESTINATION/CREATE_FAILURE',
    
    UPDATE_PENDING: 'DESTINATION/UPDATE_PENDING',
    UPDATE_SUCCESS: 'DESTINATION/UPDATE_SUCCESS',
    UPDATE_FAILURE: 'DESTINATION/UPDATE_FAILURE',

    DELETE_PENDING: 'DESTINATION/DELETE_PENDING',
    DELETE_SUCCESS: 'DESTINATION/DELETE_SUCCESS',
    DELETE_FAILURE: 'DESTINATION/DELETE_FAILURE',*/
}

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AddIcon from '@material-ui/icons/Add'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'

import { _ } from 'helpers/lang'
import { useStyles } from './AddTrip.styles'
import Layout from 'components/Layout/Layout'
import Inner from 'components/Inner/Inner'
import Title from 'components/Title/Title'
import BottomModal from 'components/BottomModal/BottomModal'
import Button from 'components/Button/Button'
import { selectPlaces } from 'stores/destination/destination.selectors'
import { tripActions } from 'stores/trip/trip.actions'
import TrashIcon from 'assets/svg/trash.svg'
import DragDropIcon from 'assets/svg/dragdrop.svg'
import { selectCustomTrip } from 'stores/trip/trip.selectors'
import store from 'stores/root-store'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { reorder } from 'utils/functions'
import { links } from 'helpers/links'

const mapStateToProps = (state) => {
    return {  
        customTrip: selectCustomTrip(state),
        places: selectPlaces(state)
    }
}

AddTrip.prototype = {
    customTrip: PropTypes.array,
}

function AddTrip({destinations, places, customTrip}) {
    const classes = useStyles()

    const [open, setOpen] = React.useState(false)
    const [selectedDestination, setSelectedDestination] = React.useState()

    const btnPlusHandleClick = () => {
        setOpen(true)
        setSelectedDestination(null)
    }

    const AddHandleClick = () => {    
        const selected = places.find(d => d.id === parseInt(selectedDestination)) 
        
        if(!selected) return

        selected.unique = new Date().getTime()

        const newList = [...customTrip.places, selected]
        const newCustomTrip = {...customTrip, places:newList}
        store.dispatch(tripActions.updateCustom(newCustomTrip))
        setOpen(false)
    }

    const handleDeleteClick = (i) => {
        const newList = [...customTrip.places]
        newList.splice(i, 1)      
        const newCustomTrip = {...customTrip, places:newList}
        store.dispatch(tripActions.updateCustom(newCustomTrip))
    }

    const handleDragEnd = (result) => {
        if (!result.destination)  return

        const newList = reorder(
            customTrip.places,
            result.source.index,
            result.destination.index
        )

        const newCustomTrip = {...customTrip, places:newList}
        store.dispatch(tripActions.updateCustom(newCustomTrip))
    }

    const availablePlaces = places.filter((place) => {
        let result = 1 
        customTrip.places.forEach((custom) => {
            if(parseInt(custom.id) === parseInt(place.id)) {                
                result = 0
            }
        })
        return (result === 1)
    })

    return (
        <Layout>
            <Inner>
                <Title backArrow={true}>{_('app.create_trips')}</Title>   
                
                <Box mt={3}>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {customTrip.places.map((destination, i) => (
                                        <Draggable key={destination.unique} draggableId={`${destination.unique}`} index={i}>
                                            {(provided, snapshot) => (
                                                <Box 
                                                    key={destination.unique}
                                                    ref={provided.innerRef} 
                                                    {...provided.draggableProps} 
                                                    {...provided.dragHandleProps} 
                                                    p={1.75} pr={2} pl={2} mt={1.5}                                                      
                                                    className={clsx(classes.item, {[classes.dragging]:snapshot.isDragging})}
                                                >
                                                    <Grid container spacing={3} alignItems="center">
                                                        <Grid item xs={2}>
                                                            <Box align="left">
                                                                <img alt={_('app.move')} src={DragDropIcon} className={classes.icon} />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={8}><Typography variant="h4">{destination.name}</Typography></Grid>
                                                        <Grid item xs={2}>
                                                            <Box align="right">
                                                                <img
                                                                    alt={_('app.trash')}
                                                                    src={TrashIcon}
                                                                    className={clsx(classes.icon, classes.trash)}
                                                                    onClick={() => handleDeleteClick(i)}
                                                                />
                                                            </Box>                                                                
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext> 
                </Box>
                {customTrip.places.length > 1 && <Box mt={2} align="center">
                    <Button to={links.trip.show('custom')}>
                        {_('app.show_trip')}
                    </Button>
                </Box>}

                <Box className={classes.btnAdd} onClick={btnPlusHandleClick}>
                    <AddIcon style={{width:30, height:30}} />
                </Box>
                {open && <BottomModal>
                    <Typography variant="h1">
                        {_('app.add_micro')}
                    </Typography>
                    
                    <Box mt={2} mb={2}>
                        <FormControl className={classes.selectField}>
                            <InputLabel id="label">{_('field.select_micro')}</InputLabel>
                            <Select native labelId="label" id="select" onChange={e => setSelectedDestination(e.target.value)}>
                                <option>---</option>      
                                {availablePlaces.map((place, i) => (
                                    <option key={place.id} value={place.id}>{place.name}</option>
                                ))}                          
                            </Select>
                        </FormControl>
                    </Box>

                    <Box mt={4} mb={2}>
                        <Button onClick={AddHandleClick}>
                            {_('app.add')}
                        </Button>
                    </Box>                    
                </BottomModal>}
            </Inner>            
        </Layout>
    )
}

export default connect(mapStateToProps)(AddTrip)
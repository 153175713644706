import { removeEmpty } from 'utils/functions'
import { required, email, password, passwordMatch } from 'utils/validation'

export const validate = values => {
    const errors = {       
        first_name: required(values.first_name),
        last_name: required(values.last_name),
        password: password(values.password),
        password_confirmation: passwordMatch(values.password)(values.password_confirmation),
        // address: required(values.address),
        // city: required(values.city),
        // postal_code: postalCode(values.postal_code),
        // phone: phoneNumber(values.phone),
        email: email(values.email),
    }
    
    return removeEmpty(errors)
}

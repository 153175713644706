import React from 'react'
import Typography from '@material-ui/core/Typography'

import Layout from 'components/Layout/Layout'
import { _ } from 'helpers/lang'

NotFound.propTypes = {
    // 
}

function NotFound(props) {
    return (
        <Layout container>
            <Typography variant="h1">404</Typography>
            <p>{_('alert.404')}</p>
        </Layout>
    )
}

export default NotFound

import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'

import Topbar from 'components/Topbar/Topbar'
import Bottombar from 'components/Bottombar/Bottombar'
import { useStyles } from './Layout.styles'
import Inner from 'components/Inner/Inner'

Layout.propTypes = {
    children: PropTypes.any,
    container: PropTypes.bool,
    greatings: PropTypes.bool
}

function Layout(props) {
    const {
        children,
        container,
        className,
        bottomBar = true,
        greatings = true,
        ...restProps
    } = props
    const classes = useStyles()

    return (
        <Box className={clsx(classes.root, className)} {...restProps}>
            <Topbar greatings={greatings} />
            {container && <Inner>{children}</Inner>}
            {!container && children}
            {bottomBar && <Bottombar />}
        </Box>
    )
}

export default Layout

import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import EmailIcon from '@material-ui/icons/Email'

import { _ } from 'helpers/lang'
import Layout from 'components/Layout/Layout'
import Inner from 'components/Inner/Inner'
import Title from 'components/Title/Title'
import StickyBottomImg from 'components/StickyBottomImg/StickyBottomImg'
import Button from 'components/Button/Button'
import { useSettings } from 'hooks/useSettings'
import Markdown from 'components/Markdown/Markdown'
import { useStyles } from './Contact.styles'

function Contact(props) {
    const classes = useStyles()
    const { getSetting, getSettingArray} = useSettings()

    const photoBotTitle = getSettingArray('contact_img', 0) ? getSettingArray('contact_img', 0).title : null
    const photoBotSrc = getSettingArray('contact_img', 0) ? getSettingArray('contact_img', 0).src : null

    return (
        <Layout className='stickyFooterContainer'>
            <Inner>
                <Title backArrow={false}>{_('app.contact_us')}</Title>
                <Box padding={3} align='center'>                    
                    <Markdown source={getSetting('contact_content')} />            
                </Box>
                {getSetting('contact_email') && <Box mt={2} align="center">
                    <Button
                        type="button"
                        className='large icon'
                        href={'mailto:'+getSetting('contact_email')}
                    ><EmailIcon />{_('app.write_us')}</Button>
                </Box>}
                <Box mt={4} mb={2} className={classes.signature}>
                    <Typography variant="h6" component='p' align="center">
                        <Markdown source={getSetting('contact_signature')}></Markdown>                         
                    </Typography>                    
                </Box>
            </Inner>
            {photoBotSrc && <StickyBottomImg alt={photoBotTitle} src={photoBotSrc} />}
        </Layout>
    )
}

export default Contact
// import 'react-app-polyfill/ie11'
// import 'react-app-polyfill/stable' // uses browserlist in package.json
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Route } from 'react-router-dom'
import { ConnectedRouter as Router } from 'connected-react-router'

import { ErrorBoundary } from 'sentry/ErrorBoundary'
import store, { history } from 'stores/root-store'
import * as serviceWorker from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import './sentry/init'
import App from 'app/App'
import RouteEnum from 'constants/RouteEnum'


ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <Router history={history}>
                <Route path={RouteEnum.HOME} component={App} />
            </Router>
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.unregister()
serviceWorker.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

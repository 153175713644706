import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'

import Button from 'components/Button/Button'
import { useStyles } from './TopbarMenu.styles'
import { _ } from 'helpers/lang'
import Link from 'components/Link/Link'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { useAuth } from 'hooks/useAuth'
import { links } from 'helpers/links'
import { RefType } from 'types/Ref.type'
import Install from 'components/Install/Install'

TopbarMenu.propTypes = {
    opened: PropTypes.bool.isRequired,
    closeMenu: PropTypes.func.isRequired,
    boundaryRef: RefType,
}

TopbarMenu.defaultProps = {
    opened: true,
}

function TopbarMenu(props) {
    const classes = useStyles()
    const {
        opened,
        closeMenu,
        boundaryRef,
    } = props

    useOnClickOutside(boundaryRef, closeMenu)

    const { isAuthenticated } = useAuth()

    var buttonsList = []
    if (isAuthenticated) {
        buttonsList = [            
            <Button key={_('app.logout')} className={clsx(classes.menubutton, 'large')} color='secondary' to={links.logout}>{_('app.logout')}</Button>,
        ]
    } else {
        buttonsList = [            
            <Button key={_('auth.createAccount')} className={clsx(classes.menubutton, 'large')} color='secondary' to={links.register}>{_('auth.createAccount')}</Button>,
            <Button key={_('auth.loginButton')} className={clsx(classes.menubutton, 'large')} color='secondary' to={links.login}>{_('auth.loginButton')}</Button>
        ]
    }

    if (!opened) return null

    return (
        <div className={classes.menu}>
            <ul className={classes.menulist}>
                <li className={classes.menulistItem}>
                    <Link color="secondary" className={classes.menulistItemLink} alt="{_('app.how')}" afterOnClick={closeMenu} href={links.how}>
                        {_('app.how')}
                    </Link>
                </li>
                <li className={classes.menulistItem}>
                    <Link color="secondary" className={classes.menulistItemLink} alt="{_('app.map_micros')}" afterOnClick={closeMenu} href={links.map}>
                        {_('app.map_micros')}
                    </Link>
                </li>
                <li className={classes.menulistItem}>
                    <Link color="secondary" className={classes.menulistItemLink} alt="{_('app.contact_us')}" afterOnClick={closeMenu} href={links.contact}>                        
                        {_('app.contact_us')}
                    </Link>
                </li>
                {isAuthenticated ? (
                    <li className={classes.menulistItem}>
                        <Link color="secondary" className={classes.menulistItemLink} alt="{_('app.my_account')}" afterOnClick={closeMenu} href={links.account}>
                            {_('app.my_account')}
                        </Link>
                    </li>
                ) : ''}
            </ul>
            <Box className={classes.buttonList} align="center">
                {buttonsList}
                <Install />
            </Box>            
            <Box className={classes.bottomList} align="center">                
                {!isAuthenticated ? (
                    <Box mt={2} align="center">
                        <Link color="secondary" className={classes.lienSmall} alt={_('auth.forgotPasswordTitle')} href={links.password.forgot}>
                            {_('auth.forgotPasswordTitle')}
                        </Link>
                    </Box>
                ) : ''}
                <Box mt={3} align="center">
                    <Link color="secondary" className={classes.lienSmall} alt={_('app.policy_terms')} href={links.policy}>
                        {_('app.policy_terms')}
                    </Link>
                </Box>
            </Box>          
        </div>
    )
}

export default TopbarMenu

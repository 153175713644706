import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'
import clsx from 'clsx'

import { env } from 'environments/base'
import { useStyles } from './Map.styles'
import { MapInfo } from './MapInfo/MapInfo'
import DestinationStamp from 'components/Destination/DestinationStamp/DestinationStamp'
import { DestinationRouteType } from 'types/DestinationRoute.type'
import { selectDestinationRoute } from 'stores/destinationRoute/destinationRoute.selectors'
import { getCenter } from 'utils/map'

const mapStateToProps = (state) => {
    return {
        destinationRoute: selectDestinationRoute(state),
    }
}

Map.propTypes = {
    active: PropTypes.object,    
    destinationRoute: DestinationRouteType,
    hasTrip: PropTypes.bool,
    apiCallback: PropTypes.func,
    markerClick: PropTypes.func
}

export function Map({markers, hasTrip, destinationRoute, apiCallback, markerClick}) {    
    const classes = useStyles()

    const [active, setActive] = useState(0) 
    const [center, setCenter] = useState(null)     
    const [directionsRenderer, setDirectionsRenderer] = useState(null) 
    const [mapInstance, setMapInstance] = useState(null) 
    const [googleMaps, setGoogleMaps] = useState(null) 

    useEffect(() => {        
        if (!googleMaps || !mapInstance) return

        initRenderer(!hasTrip)
        drawTrip(hasTrip)
    }, [markers, hasTrip, googleMaps, directionsRenderer])

    useEffect(() => {             
        setCenter(getCenter(markers))
    }, [])

    if(!center) return null

    const handleMarkerClick = (e, marker) => {
        e.preventDefault()
        setActive(marker)
    }  

    const MarkerLayout = ({ text, marker, order, distance, destinationRoute }) => {

        const stamp = (destinationRoute 
            ? destinationRoute.stamps.find(stamp => stamp.id === marker.id)
            : null
        )

        const completed = (stamp ? true : false)

        let stampText = null
        if(distance) stampText = Math.round(distance / 1000)+' km'
        if(hasTrip) stampText = order

        return <DestinationStamp 
            className={clsx('marker', (hasTrip ? 'order' : (distance ? 'distance' : '')))}
            completed={completed}
            action={(e) => {
                handleMarkerClick(e, marker)
                markerClick(true)
            }} 
            alt={text} 
            id={marker.id} 
            stampText={stampText}
        />
    }

    const initRenderer = (shouldResetTrip = false) => {
        if(shouldResetTrip) {
            if(directionsRenderer){
                directionsRenderer.setMap(null)
                setDirectionsRenderer(null)
            }
            return 
        }

        let directionsRendererInstance        

        if(!directionsRenderer){
            directionsRendererInstance = new googleMaps.DirectionsRenderer()  
            setDirectionsRenderer(directionsRendererInstance)
        }else
            directionsRendererInstance = directionsRenderer
            
        directionsRendererInstance.setMap(null)
    }

    const drawTrip = (shouldRenderTrip = true) => {      
        if(!shouldRenderTrip || !directionsRenderer || markers.length === 0) return

        const directionsService = new googleMaps.DirectionsService()

        // Create start/end/waypoints
        const start = new googleMaps.LatLng(markers[0].map_lat, markers[0].map_lng)
        const end = new googleMaps.LatLng(markers[markers.length - 1].map_lat, markers[markers.length - 1].map_lng)
        
        let waypts = []
        markers.forEach((marker, i) => {
            const lnglat = new googleMaps.LatLng(marker.map_lat, marker.map_lng)

            waypts.push({
                location: lnglat,
                stopover: false,
            })
        })  

        // Styles
        directionsRenderer.setOptions({
            suppressMarkers: true,
            polylineOptions: {
                strokeColor: '#344C5A',
                strokeOpacity: 0.95,
                strokeWeight: 3,
            }
        })
        directionsRenderer.setMap(mapInstance) 

        // Get Directions&Draw
        directionsService.route(
            {
                origin: start,
                destination: end,
                waypoints: waypts,
                optimizeWaypoints: true,
                travelMode: googleMaps.TravelMode.DRIVING,
            },
            (response, status) => {
                if (status === "OK") {
                    if(!directionsRenderer) return

                    directionsRenderer.setDirections(response)      
                    
                    let totalKm = 0
                    response.routes.forEach((route) => {
                        route.legs.forEach((leg) => {
                            totalKm += leg.distance.value
                        })
                    })

                    // Meter to Km and round
                    totalKm = Math.round((totalKm / 1000))

                    apiCallback(totalKm)

                } else {
                    window.alert("Directions request failed due to " + status)
                }
            }
        ) 
    }

    const handleApiLoaded = (map, maps) => {
        setMapInstance(map)
        setGoogleMaps(maps)           
    }

    const defaultMapOptions = {
        fullscreenControl: false,
    }

    return (
        <div style={{ width: '100%' }} className={classes.root}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: env.gmapKey }} 
                defaultCenter={center}
                options={defaultMapOptions}
                defaultZoom={8}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            >
                {markers.map((marker, i) => (
                    <MarkerLayout
                        key={i}
                        order={i+1}
                        placeId={marker.placeId}
                        lat={marker.map_lat}
                        lng={marker.map_lng}
                        text={marker.name}
                        marker={marker}
                        destinationRoute={destinationRoute}
                        distance={marker.distance}
                    />                    
                ))}
            </GoogleMapReact>
            {active !== 0 && <MapInfo marker={active} closeAction={() => {
                setActive(0)
                markerClick(false)
            }} />}
        </div>
    )
}

export default connect(mapStateToProps)(Map)

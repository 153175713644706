import React from 'react'
import Box from '@material-ui/core/Box'
import CloseIcon from '@material-ui/icons/Close'

import { useStyles } from './MapInfo.styles'
import DestinationDetail from 'components/Destination/DestinationDetail/DestinationDetail'

export function MapInfo({ marker, closeAction }, props) {
    const classes = useStyles()

    return (
        <Box pt={3} pl={3} pr={3} pb={0} className={classes.root} align="center">
            <CloseIcon className={classes.close} onClick={closeAction} />
            <Box className={classes.contain} pb={2}>
                <DestinationDetail destination={marker} />
            </Box>            
        </Box>
    )
}

export default MapInfo

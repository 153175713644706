const RouteEnum = {
    HOME: '/',
    CONTACT: '/contact',
    HOW: '/how',
    HELP: '/help',
    LOGIN: '/login',
    LOGOUT: '/logout',
    LOGOUT_ALL_DEVICES: '/logout-all-devices',
    ACCOUNT: '/account',
    SELFIE: '/selfie',
    REGISTER: '/register',
    SEARCH: '/search/:value',
    POLICY: '/policy-terms',
    NOTFOUND: '/not-found',
    PASSWORD: {
        FORGOT: '/password/forgot',
        RESET: '/password/reset/:token/:email',
    },
    VERIFY: '/verify/:token',
    EXAMPLE: {
        LIST: '/example',
        NEW: '/example/new',
        EDIT: '/example/edit/:slug',
        SHOW: '/example/show/:slug',
    },
    //MAP: '/map',
    MAP: {
        LIST: '/map',
        SHOW: '/map/:slug',
        CUSTOM: '/map/custom',
    },
    STAMP: {
        LIST: '/stamp',
        SCAN: '/stamp/scan/:hashid?',
        ADD: '/stamp/add/:hashid',
    },
    REWARD: '/reward',
    TRIP: {
        LIST: '/trip',
        ADD: '/trip/add',
    },
}

export default RouteEnum

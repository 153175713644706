import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
        zIndex: 100,
        backgroundColor: '#ffffff',       
    },  
    contain: {
        maxHeight: 'calc(100vh - 250px)',
        overflow: 'auto',
        '@media (max-height:615px)' : {            
            maxHeight: 'calc(90vh - 250px)',
        }
    },
    close: {
        position: 'absolute',
        right: 10,
        top: 10,
        zIndex: 101,
        fontSize: '2.25rem',
        cursor: 'pointer',
        transition: 'all 200ms ease-out',
        '&:hover': {
            color: theme.palette.primary.light
        }
    },    
}))

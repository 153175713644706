import React from 'react'
import MarkdownBase from 'react-markdown'
import htmlParser from 'react-markdown/plugins/html-parser'

import './Markdown.css'
import ImageRenderer from './renderers/ImageRenderer'
import LinkRenderer from './renderers/LinkRenderer'
import EmphasisRenderer from './renderers/EmphasisRenderer'
import TableCellRenderer from './renderers/TableCellRenderer'
import ParagraphRenderer from './renderers/ParagraphRenderer'
import HeadingRenderer from './renderers/HeadingRenderer'

const parseHtml = htmlParser({
    isValidNode: node => {
        return ['tag', 'text'].includes(node.type)
    },
})

const renderers = {
    paragraph: ParagraphRenderer,
    heading: HeadingRenderer,
    image: ImageRenderer,
    link: LinkRenderer,
    emphasis: EmphasisRenderer,
    tableCell: TableCellRenderer,
}

const Markdown = props => (
    <div className="markdown">
        <MarkdownBase
            escapeHtml={false}
            astPlugins={[parseHtml]}
            renderers={renderers}
            {...props}
        />
    </div>
)
    
export default Markdown

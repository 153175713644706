import { useSelector } from 'react-redux'

const mapState = (state) => ({ settings: state.settings })

export const useSettings = () => {
    const { settings } = useSelector(mapState)

    const getSetting = slug => {
        const setting = settings.find(s => s.slug === slug)

        if(setting)
            return setting.value
        else
            return null
    }

    const getSettingArray = (slug, index) => {
        const setting = settings.find(s => s.slug === slug)

        if(setting)
            return setting.value[index]
        else
            return null
    }

    return { settings, getSetting, getSettingArray}
}
import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Home from './Home/Home'
import Contact from './Contact/Contact'
import How from './How/How'
import Login from './Login/Login'
import Account from './Account/Account'
import ResetPassword from './ResetPassword/ResetPassword'
import NotFound from './NotFound/NotFound'
import MapLayout from './MapLayout/MapLayout'

import { useAuth } from 'hooks/useAuth'
import { links } from 'helpers/links'
import RouteEnum from 'constants/RouteEnum'
import { PrivateRoute } from 'components/PrivateRoute/PrivateRoute'
import Stamps from './Stamps/Stamps'
import Reward from './Reward/Reward'
import Register from './Register/Register'
import ScanStamp from './ScanStamp/ScanStamp'
import AddStamp from './AddStamp/AddStamp'
import PolicyTerms from './PolicyTerms/PolicyTerms'
import Selfie from './Selfie/Selfie'
import Help from './Help/Help'
import Trip from './Trip/Trip'
import AddTrip from './Trip/AddTrip/AddTrip'

export const Routes = () => {
    const { isAuthenticated } = useAuth()      

    return <Switch>

        <Route exact path={RouteEnum.LOGOUT} component={Login} />
        <Route
            exact
            path={RouteEnum.LOGOUT_ALL_DEVICES}
            render={props => <Login {...props} requestLogoutAllDevices />}
        />
        <PrivateRoute
            inverse
            redirect
            exact
            redirectPath={links.home}
            path={RouteEnum.LOGIN}
            message={false}
            component={Login} />

        <Route redirect exact path={RouteEnum.REGISTER} component={Register} />    

        <Route exact path={RouteEnum.PASSWORD.RESET} component={ResetPassword} />

        <Route exact path={RouteEnum.HOME} component={Home} />        
        <Route exact path={RouteEnum.CONTACT} component={Contact} />
        <Route exact path={RouteEnum.HOW} component={How} />
        <Route exact path={RouteEnum.HELP} component={Help} />
        <Route exact path={RouteEnum.POLICY} component={PolicyTerms} />
        <Route exact path={RouteEnum.NOTFOUND} component={NotFound} />
        <PrivateRoute redirect exact path={RouteEnum.ACCOUNT} component={Account} />             

        <Route exact path={RouteEnum.MAP.LIST} component={MapLayout} />
        <Route exact path={RouteEnum.MAP.CUSTOM} component={props => <MapLayout {...props} custom />} />
        <Route exact path={RouteEnum.MAP.SHOW} component={MapLayout} />
        <PrivateRoute redirect exact path={RouteEnum.STAMP.LIST} component={Stamps} />
        <PrivateRoute redirect path={RouteEnum.STAMP.SCAN} component={ScanStamp} />
        <PrivateRoute redirect exact path={RouteEnum.STAMP.ADD} component={AddStamp} />
        <PrivateRoute redirect exact path={RouteEnum.REWARD} component={Reward} />     
        <PrivateRoute redirect exact path={RouteEnum.SELFIE} component={Selfie} />  
        <PrivateRoute redirect exact path={RouteEnum.TRIP.LIST} component={Trip} />        
        <PrivateRoute redirect exact path={RouteEnum.TRIP.ADD} component={AddTrip} />

        <Route component={() => <Redirect to={isAuthenticated ? RouteEnum.HOME : RouteEnum.LOGIN} /> } />
    </Switch>
}

export default Routes

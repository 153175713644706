import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import { authStore } from 'stores/auth/auth.reducer'
import { alertStore } from 'stores/alert/alert.reducer'
import { destinationStore } from 'stores/destination/destination.reducer'
import { destinationRouteStore } from 'stores/destinationRoute/destinationRoute.reducer'
import { settingsStore } from 'stores/settings/settings.reducer'
import { tagStore } from 'stores/tag/tag.reducer'
import { userInfoStore } from 'stores/userInfo/userInfo.reducer'
import { tripStore } from './trip/trip.reducer'
import { autorizedBrowserAPIsStore } from './autorizedBrowserAPIs/autorizedBrowserAPIs.reducer'
import { placeDistanceStore } from './placeDistance/placeDistance.reducer'

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    auth: authStore,
    alerts: alertStore,    
    destinations: destinationStore,
    destinationRoutes: destinationRouteStore,
    settings: settingsStore,
    autorizedBrowserAPIs: autorizedBrowserAPIsStore,
    tags: tagStore,
    trips: tripStore,
    userInfo: userInfoStore,
    placeDistances: placeDistanceStore,
})

export default createRootReducer

import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({    
    filterBtnContainer: {
        position: 'relative',
        marginTop: theme.spacing(1.5),

        '&.map': {
            marginTop: 0,
            position: 'absolute',
            width: '100%',
            maxWidth: '800px',
            zIndex: 98,
            top: theme.spacing(10)
        }
    },
    filterBtn: {
        height: '2.5rem',
        borderRadius: '0.6rem',
        backgroundColor: theme.palette.primary.darker,        
        zIndex: 95,
        textTransform: 'none',

        '&.map': {
            //position: 'absolute',
            //top: theme.spacing(4),
        }
    },
    filterBtnIcon: {
        marginRight: 5
    },
    listeBtn: {
        height: '2.5rem',
        borderRadius: '0.6rem',
        textTransform: 'none',
    },
    list: {
        paddingTop: 0
    },
    tripInfo: {
        cursor: 'pointer'
    },
    tripTitle: {
        [theme.breakpoints.down('380')]: {            
            fontSize: '0.95rem',
        },
    }
}))

import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { _ } from 'helpers/lang'
import { authActions } from 'stores/auth/auth.actions'
import ResetPasswordForm from './ResetPasswordForm/ResetPasswordForm'
import { HistoryType, MatchType } from 'types/ReactRouter.type'
import Layout from 'components/Layout/Layout'
import Inner from 'components/Inner/Inner'
import Title from 'components/Title/Title'

const mapStateToProps = (state, { history, match }) => {
    return {
        history,
        match,
    }
}

const mapDispatchToProps = {
    resetPassword: authActions.resetPassword,
}

const propTypes = {
    history: HistoryType.isRequired,
    match: MatchType.isRequired,
    resetPassword: PropTypes.func.isRequired,
}

class ResetPassword extends React.Component {
    handleSubmit = (data, { setSubmitting }) => {
        setSubmitting(true)
        this.props.resetPassword(data)
            .then(success => {
                if (!success) setSubmitting(false)
            })
    }

    render() {
        return <Layout bottomBar={false}>
            <Inner mb={0}>
                <Title backArrow={false}>{_('auth.resetPasswordTitle')}</Title>
                <ResetPasswordForm handleSubmit={this.handleSubmit} token={this.props.match.params.token} email={this.props.match.params.email} />
            </Inner>             
        </Layout>
    }
}

ResetPassword.propTypes = propTypes

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)

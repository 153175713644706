import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

const getPadding = () => {
    let padding = 2.5
    const width = window.innerWidth
    switch(true) {
        case (width < 320) : padding = 1; break
        case (width < 360) : padding = 1.25; break
        case (width < 375) : padding = 2; break 
        default: break
    }
    return padding
}

Inner.propTypes = {
    children: PropTypes.any,
    grid: PropTypes.bool,
    p: PropTypes.number
}

Inner.defaultProps = {
    grid: true,
    px: getPadding(),
    py: 2.5
}

function Inner({ children, grid, ...restProps }) {
    return <Box {...restProps}>
        {grid && <Grid container justify="center">
            <Grid xs={12} md={10} item>{children}</Grid>
        </Grid>}
        {!grid && children}
    </Box>    
}

export default Inner

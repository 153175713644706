import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    thanks: {
        fontSize: '1.75rem',
        textTransform: 'none',
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(1.5)
    },
    btn: {
        '& svg': {            
            transform: "rotate(-42deg)",
            transition: 'all 200ms ease-out',
        },        
        '&:hover': {
            '& svg': {
                transform: "rotate(-320deg)"
            }
        }
    },       
}))

const {
    REACT_APP_ENV,
    REACT_APP_SENTRY_DSN,
} = process.env

const environment = require(`./${REACT_APP_ENV}`).default

export const env = {
    sentryDSN: REACT_APP_SENTRY_DSN,
    requestTimeout: 15000,
    basename: '/',
    ...environment,
}

if (env.debugMode) {
    // eslint-disable-next-line no-console
    console.table(env)
}
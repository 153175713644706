import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { useStyles } from './Title.styles'
import Link from 'components/Link/Link'
import { _ } from 'helpers/lang'
import { history } from 'stores/root-store'

Title.propTypes = {
    children: PropTypes.any,
    backArrow: PropTypes.bool,
}

Title.defaultProps = {   
    backArrow: true
}

const handleClick = (e) => {
    e.preventDefault()
    history.goBack()
}

function Title(props) {
    const { children, backArrow, ...restProps } = props
    const classes = useStyles()
       
    return <Box mt={1} {...restProps} position='relative'>
        {backArrow && <Link 
            className={classes.arrow} 
            alt={_('app.previous')} 
            onClick={handleClick}
        >
            <ArrowBackIcon />
        </Link>}   
        <Box pl={2.75} pr={2.75}>
            <Typography variant="h1" align="center">
                {children}
            </Typography>
        </Box>        
    </Box>
    
}

export default Title

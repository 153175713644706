import PropTypes from 'prop-types'
import { DestinationPlaceType } from './DestinationPlace.type'


export const TripType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,    
    places: PropTypes.arrayOf(DestinationPlaceType).isRequired,
    img: PropTypes.string,
    resume: PropTypes.string,
    desc: PropTypes.string,

    // Variable FrontEnd
    totalKm: PropTypes.number, 
})

import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'

import { useStyles } from './BottomModal.styles'

BottomModal.propTypes = {
    children: PropTypes.any,
    slideAction: PropTypes.func
}

function BottomModal(props) {
    const {
        children,
        className,
        slideAction,
        ...restProps
    } = props
    const classes = useStyles()

    const handleClick = () => {
        if(slideAction)
            slideAction()
    }

    return (
        <Box p={2.5} pt={3} pb={3} className={clsx(classes.root, className)} {...restProps} align="center">
            <Box className={classes.spacer} onClick={handleClick}></Box>
            <Box mt={2.5} align="center" className={classes.contain}>
                {children}
            </Box>
        </Box>
    )
}

export default BottomModal

import { httpClient } from 'helpers/http'
//import fakeData from './data.json'

export const userInfoService = {
    list,
    show,
    update,
}

const resource = '/me/info'

function list(filters = undefined) {
    return httpClient.GET(resource + (filters ? filters : ''))
    //return Promise.resolve({ data: fakeData })
}

function show(number) {
    return httpClient.GET(`${resource}/${number}`)
}

function update(data) {
    if(data.phone)
        data.phone = data.phone.replace(/[\s()-]/g,'')
    return httpClient.PUT(resource, { data })
}

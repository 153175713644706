import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import { useStyles } from './DestinationTag.styles'

DestinationTag.propTypes = {
    items: PropTypes.array
}

DestinationTag.defaultProps = {
}

function DestinationTag(props) {
    const {
        items,
        className,
        ...rest
    } = props

    const classes = useStyles(props)

    return (
        <Grid
            container
            justify="center"
            alignItems="center"
            spacing={0}
            {...rest}
        >
            {items.map((item, i) => (
                <Grid 
                    key={i} 
                    item 
                    className={classes.item}
                    align="center"
                >
                    <Grid container
                        justify="center"
                        alignItems="center"
                        className={classes.icon}><img alt={item.name} src={item.icon} /></Grid>
                    {item.name}
                </Grid>
            ))}
        </Grid>
    )
}

export default DestinationTag

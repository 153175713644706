import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    container: {
        textAlign: 'center',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    buttonlist: {
        margin: theme.spacing(3, 0, 2),
    },
    link: {
        color: theme.palette.primary[900],
        fontWeight: '600',
    },
    header: {
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(0, 0, 3),
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(0, 0, 5),
        },
    },
    footer: {
        width: '50%',
        margin: theme.spacing(3, 0, 0),
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(5, 0, 0),
        },
    },
    logo: {
        maxWidth: '100%',
    },      
}))

import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

import { useStyles } from './DestinationDetail.styles'
import DestinationStamp from '../DestinationStamp/DestinationStamp'
import DestinationTag from '../DestinationTag/DestinationTag'
import { DestinationRouteType } from 'types/DestinationRoute.type'
import { selectDestinationRoute } from 'stores/destinationRoute/destinationRoute.selectors'
import Markdown from 'components/Markdown/Markdown'
import { useAuth } from 'hooks/useAuth'
import { connect } from 'react-redux'
import { usePlaceDistance } from 'hooks/usePlaceDistance'


const mapStateToProps = (state) => {
    return {
        destinationRoute: selectDestinationRoute(state)
    }
}

DestinationDetail.propTypes = {    
    destination: PropTypes.object.isRequired,
    closed: PropTypes.bool,
    allAddress: PropTypes.bool,
    destinationRoute: DestinationRouteType,
}

DestinationDetail.defaultProps = {
    closed: false,
    allAddress: false
}

function DestinationDetail(props) {
    const {     
        destination, 
        allAddress,
        closed,
        destinationRoute,  
    } = props   

    const { getPlaceDistance} = usePlaceDistance()
    const { isAuthenticated } = useAuth()
    const classes = useStyles(props)

    const [isClosed, setClosed] = React.useState(closed)

    const addressList = []
    let tags = []
    if(allAddress)
        destination.places.forEach((address) => {
            addressList.push(address)
            address.tags.forEach((tag) => {
                if(!tags.find((t) => t.id === tag.id))
                    tags.push(tag)                                 
            })            
        })        
    else {        
        if(destination.placeId){
            const place = destination.marker.places.find(place => place.id === destination.placeId)
            if(place) {
                addressList.push(place)            
                tags = place.tags
            }        
            destination.name = destination.marker.name
            destination.id = destination.marker.id
            destination.logo = destination.marker.logo
            destination.description = destination.marker.description

            if(!destination.places)
                destination.places = destination.marker.places
        }else{            
            addressList.push(destination.places[0])
            tags = destination.places[0].tags
        }
    }

    const distance = (getPlaceDistance(destination.places[0].id) ? getPlaceDistance(destination.places[0].id).distance : null)    

    const ToggleLayout = ({ open, ...rest}) => {     
        if(!open) return <AddIcon {...rest} />
        else return <RemoveIcon {...rest} />
    }

    return (
        <Box className={classes.root} align="center">
            {distance && <Box className={classes.distance}>
                {Math.round(distance / 1000)+' km'}
            </Box>}             
            <Box mb={1} mt={1}>
                <Grid 
                    container
                    justify="flex-start"
                    alignItems="center"
                >
                    <Grid item xs={2} align="left">
                        {isAuthenticated && destinationRoute && <DestinationStamp 
                            alt={destination.name} 
                            completed={destinationRoute.stamps.find(stamp => stamp.id === destination.id)} 
                            destinationId={destination.id} 
                        />}
                    </Grid>
                    <Grid item xs={8}>
                        <img className={classes.img} alt={destination.name} src={destination.logo} />
                    </Grid>
                    <Grid item xs={2} align="right">
                        {allAddress && <ToggleLayout className={classes.toggleBtn} open={!isClosed} onClick={() => setClosed(!isClosed)} />}
                    </Grid>
                </Grid>
            </Box>   
            <Box>
                <Typography component="h2" variant="subtitle1">
                    {destination.name}
                </Typography>
                {!isClosed && addressList.map((address, i) => (
                    <Box 
                        key={i} 
                        mb={1.5}
                        mt={1}
                        className={classes.info}
                    >
                        <Typography variant="h6">
                            {address.address}
                            { addressList.length > 1 && getPlaceDistance(address.id) && <Box ml={1} className={classes.distanceLabel}>
                                ({Math.round(getPlaceDistance(address.id).distance / 1000)}km)
                            </Box>}
                        </Typography>
                        <Typography variant="h4">
                            {address.phone}
                        </Typography>
                    </Box>
                ))}
                {isClosed && <Box
                    mt={1}
                    className={classes.info}
                >
                    <Typography variant="h6">
                        {destination.places[0].address}
                    </Typography>                    
                </Box>}
            </Box>
            {!isClosed && <>
                <Divider />
                <Typography variant="h6" component="div" color="textSecondary">                    
                    <Markdown source={destination.description} />
                </Typography>
                <Divider />
                <DestinationTag items={tags} />
            </>} 
        </Box>
    )
}

export default connect(mapStateToProps)(DestinationDetail)

import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: props => ({
        cursor: 'pointer',
        display: 'flex',
        position: 'fixed',
        right: theme.spacing(2),
        top: theme.spacing(2.25),
        alignItems: 'center',
        justifyContent: 'center',
        height: theme.spacing(6),
        width: theme.spacing(6),
        zIndex: 9996,  
    }),
}))

import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(1),
        left: theme.spacing(1),
        right: theme.spacing(1),
        zIndex: 20,
    },
    installButton: {
        background: theme.palette.primary.dark,
        // background: '#529648',
    }
}))

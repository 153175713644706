
import { createSelector } from 'reselect'

export class TripsSelector {
    static selectTrips(trips) {
        return trips.premade
    }

    static selectTripById(trips, id) {
        return trips.premade.find((d) => d.id === id)
    }

    static selectCustomTrip(trips) {
        return trips.custom
    }
}

export const selectTrips = createSelector(
    (state) => state.trips,
    TripsSelector.selectTrips
)

export const selectTripById = createSelector(
    (state) => state.trips,
    (state, id) => id,
    TripsSelector.selectTripById
)

export const selectCustomTrip = createSelector(
    (state) => state.trips,
    TripsSelector.selectCustomTrip
)
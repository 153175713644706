import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import PropTypes from 'prop-types'

import Routes from 'app/Routes'
import Alert from 'components/Alert/Alert'
import { fetchData } from 'helpers/data'
import { theme } from 'helpers/theme'
import { HistoryType } from 'types/ReactRouter.type'
import { useAuth } from 'hooks/useAuth'
import { destinationRouteActions } from 'stores/destinationRoute/destinationRoute.actions'
import { userInfoActions } from 'stores/userInfo/userInfo.actions'
import PwaProvider from 'helpers/PwaProvider'

const propTypes = {
    list: PropTypes.func.isRequired,
    history: HistoryType.isRequired,
    getInfo: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
    list: destinationRouteActions.list,
    getInfo: userInfoActions.list,
}

function App({ list, history, getInfo }) {
    const { isAuthenticated } = useAuth()    

    useEffect(() => {
        fetchData(true)

        history.listen((location, action) => {
            // on location change
            window.scrollTo(0, 0)
            fetchData()
        })
    }, [])

    useEffect(() => {
        if (isAuthenticated) {
            list()
            getInfo()
        }
    }, [isAuthenticated])

    return (
        <PwaProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Routes />
                <Alert />
            </ThemeProvider>
        </PwaProvider>
    )
}

App.propTypes = propTypes

export default connect(null, mapDispatchToProps)(App)

import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'

import { _ } from 'helpers/lang'
import MapIcon from 'assets/svg/carte.svg'
import StampIcon from 'assets/svg/timbre.svg'
import QrIcon from 'assets/svg/qr.svg'
import TripIcon from 'assets/svg/itineraire.svg'
import { useStyles } from './Bottombar.styles'
import RouteEnum from 'constants/RouteEnum'
import { links } from 'helpers/links'

const Bottombar = () => {
    const classes = useStyles()
    const history = useHistory()
    const { pathname } = useLocation()

    const handleClick = url => {
        history.push(url)
    }

    const actionClasses = {
        root: classes.actionRoot,
        wrapper: classes.actionWrapper,
        label: classes.actionLabel,
    }

    return (
        <BottomNavigation
            showLabels
            className={classes.bottombar} 
        >
            <BottomNavigationAction
                label={_('app.map_micros')}
                icon={<img alt={_('app.map_micros')} src={MapIcon} />}
                classes={actionClasses}
                className={pathname === RouteEnum.MAP.LIST ? classes.active : ''}
                onClick={() => handleClick(RouteEnum.MAP.LIST)}
            />
            <BottomNavigationAction
                label={_('app.trips')}
                icon={<img alt={_('app.trips')} src={TripIcon} />}
                classes={actionClasses}
                className={pathname.startsWith(RouteEnum.TRIP.LIST) ? classes.active : ''}
                onClick={() => handleClick(RouteEnum.TRIP.LIST)}
            />
            <BottomNavigationAction
                label={_('app.my_stamps')}
                icon={<img alt={_('app.my_stamps')} src={StampIcon} />}
                classes={actionClasses}
                className={(pathname === RouteEnum.STAMP.LIST ? classes.active : '')}
                onClick={() => handleClick(RouteEnum.STAMP.LIST)}
            />
            <BottomNavigationAction
                label={_('app.add_stamp')}
                icon={<img alt={_('app.add_stamp')} src={QrIcon} />}
                classes={actionClasses}
                className={(pathname.startsWith(links.stamp.scan) ? classes.active : '')}
                onClick={() => handleClick(links.stamp.scan)}
            />            
        </BottomNavigation>
    )
}

export default Bottombar

const development = {
    environment: 'development',
    debugMode: true,
    debugHttpCalls: false,
    appUrl: "https://nodeserver.lndo.site/",
    apiUrl: "https://route-des-bieres.lndo.site/api/v1",
    storageUrl: "https://route-des-bieres.lndo.site/storage/",
    gmapKey: "AIzaSyAcyBVRM7Bt6JE2E5m-RH9Dde-mOcOqXH4",
}

export default development

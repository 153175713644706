import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { _ } from 'helpers/lang'
import Button from 'components/Button/Button'
import Modal from '../Modal'
import Markdown from 'components/Markdown/Markdown'
import { useSettings } from 'hooks/useSettings'
import { destinationRouteActions } from 'stores/destinationRoute/destinationRoute.actions'

const mapDispatchToProps = {
    resetRoute: destinationRouteActions.resetRoute,  
}

ModalReset.propTypes = {
    resetRoute: PropTypes.func.isRequired,
}

function ModalReset({closeAction, resetRoute, ...props}) {
    const { getSetting } = useSettings()

    const handleResetClick = () => {
        resetRoute()
        closeAction()
    }

    return (
        <Modal closeAction={closeAction} {...props}>
            <Typography variant="h1">
                {_('app.reset_route')}
            </Typography>
            <Box mt={3} mb={3}>
                <Markdown source={getSetting('reset_content')} />
            </Box>          
            <Box mt={4} mb={3}>
                <Button
                    type="button"
                    color='secondary'
                    className='large'
                    onClick={handleResetClick}
                >{_('app.reset')}</Button>
            </Box>                
        </Modal>
    )
}

export default connect(null, mapDispatchToProps)(ModalReset)

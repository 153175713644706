import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ReplayIcon from '@material-ui/icons/Replay'
import clsx from 'clsx'

import { _ } from 'helpers/lang'
import { useStyles } from './Reward.styles'
import Layout from 'components/Layout/Layout'
import Inner from 'components/Inner/Inner'
import { DestinationRouteType } from 'types/DestinationRoute.type'
import { selectDestinationRoute } from 'stores/destinationRoute/destinationRoute.selectors'
import { destinationRouteActions } from 'stores/destinationRoute/destinationRoute.actions'
import Title from 'components/Title/Title'
import RouteEnum from 'constants/RouteEnum'
import Button from 'components/Button/Button'
import StickyBottomImg from 'components/StickyBottomImg/StickyBottomImg'
import { useAuth } from 'hooks/useAuth'
import { useSettings } from 'hooks/useSettings'
import AccountForm from 'app/Account/AccountForm/AccountForm'
import ModalReset from 'components/Modal/ModalReset/ModalReset'
import { UserInfoType } from 'types/UserInfo.type'
import { selectUserInfo } from 'stores/userInfo/userInfo.selectors'

const mapStateToProps = (state, { match }) => {
    return {
        destinationRoute: selectDestinationRoute(state),
        userInfo: selectUserInfo(state)      
    }
}

const mapDispatchToProps = {
    sendRewardForm: destinationRouteActions.sendRewardForm,  
}

Reward.propTypes = {
    destinationRoute: DestinationRouteType,
    sendRewardForm: PropTypes.func.isRequired,  
    userInfo: UserInfoType,
}

function Reward({sendRewardForm, destinationRoute, userInfo, ...props}) {
    useAuth(true)
    const { getSetting, getSettingArray } = useSettings()
    const classes = useStyles()
    const history = useHistory()
    
    const [submited, setSubmited] = React.useState(false)   
    const [modalState, setModalState] = React.useState(false)   

    if(!destinationRoute) return null

    if(destinationRoute.status <= 0) // IF Stamps Route not completed
        history.push(RouteEnum.STAMP.LIST)

    const handleSubmit = (data) => {
        sendRewardForm(data)
        setSubmited(true)
    }

    const photoBotTitle = getSettingArray('thanks_img', 0) ? getSettingArray('thanks_img', 0).title : null
    const photoBotSrc = getSettingArray('thanks_img', 0) ? getSettingArray('thanks_img', 0).src : null

    return (
        <Layout className={submited && photoBotSrc ? 'stickyFooterContainer' : ''}>
            <Inner>
                {!submited && 
                    <>
                        <Title backArrow={false}>{_('app.getReward')}</Title>
                        <Box padding={3}>                    
                            <Typography variant="h4" align="center">
                                {_('app.rewardText')}
                            </Typography>
                        </Box>
                        <AccountForm autoDisabled error={props.error} handleSubmit={handleSubmit} userInfo={userInfo} />
                    </>
                }
                {submited && 
                    <Box align="center">
                        <Typography variant="h1" element="h2" className={classes.thanks} align="center">
                            {_('app.thanks')}!
                        </Typography>                                         
                        <Typography variant="h4" component="p" align="center">
                            {getSetting('thanks_content')}                             
                        </Typography><br />
                        <Typography variant="h4" component="p" align="center">
                            {getSetting('destinationRoute_reset_content')}
                        </Typography>
                        <Typography variant="h4" component="p" align="center">
                            ...
                        </Typography>
                        <Box mt={2}>
                            <Button
                                type="button"
                                className={clsx(classes.btn, 'large', 'icon')}
                                onClick={() => {setModalState(true)}}
                            ><ReplayIcon />{_('app.reset_route')}</Button>
                        </Box>
                    </Box>
                }
            </Inner>
            {submited && photoBotSrc && <StickyBottomImg alt={photoBotTitle} src={photoBotSrc} /> }
            {modalState && <ModalReset closeAction={() => {setModalState(false)}} />}  
        </Layout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Reward)
const HttpStatusEnum = {
    Success: 200,
    Unauthenticated: 401,
    NotFound: 404,
    Conflict: 409,
    TooManyRequests: 429,
    InternalServerError: 500,
}

export default HttpStatusEnum

export const destinationRouteConstants = {
    LIST_PENDING: 'DESTINATIONROUTE/LIST_PENDING',
    LIST_SUCCESS: 'DESTINATIONROUTE/LIST_SUCCESS',
    LIST_FAILURE: 'DESTINATIONROUTE/LIST_FAILURE',

    SHOW_PENDING: 'DESTINATIONROUTE/SHOW_PENDING',
    SHOW_SUCCESS: 'DESTINATIONROUTE/SHOW_SUCCESS',
    SHOW_FAILURE: 'DESTINATIONROUTE/SHOW_FAILURE',
    
    ADD_STAMP_PENDING: 'DESTINATIONROUTE/ADD_STAMP_PENDING',
    ADD_STAMP_SUCCESS: 'DESTINATIONROUTE/ADD_STAMP_SUCCESS',
    ADD_STAMP_FAILURE: 'DESTINATIONROUTE/ADD_STAMP_FAILURE',

    SEND_REWARD_FORM_PENDING: 'DESTINATIONROUTE/SEND_REWARD_FORM_PENDING',
    SEND_REWARD_FORM_SUCCESS: 'DESTINATIONROUTE/SEND_REWARD_FORM_SUCCESS',
    SEND_REWARD_FORM_FAILURE: 'DESTINATIONROUTE/SEND_REWARD_FORM_FAILURE',
}

import { makeStyles } from '@material-ui/core/styles'
import StampBg from 'assets/img/stamp-bg.png'

export const useStyles = makeStyles(theme => ({
    bg: {
        backgroundImage: `url(${StampBg})`,
        backgroundRepeatX: 'repeat',
        backgroundRepeatY: 'no-repeat',
        backgroundColor: 'transparent',
        backgroundPosition: 'center', 
        height: 100,
        width: '100%',
        animation: `$enter 2500ms linear 200ms infinite`,
        marginBottom: '-1rem'
    },
    '@keyframes enter': {
        '0%': {
            backgroundPosition: '0% center'
        },
        '100%': {
            backgroundPosition: '-73px center'
        },
    },
    stamp: {
        backgroundColor: theme.palette.primary.darker,
        width: 100,
        height: 100,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    instruction: {
        backgroundColor: '#F2F2F2'
    },   
}))

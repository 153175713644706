import { removeEmpty } from 'utils/functions'
import { password, passwordMatch } from 'utils/validation'

export const validate = values => {
    const errors = {
        password: password(values.password),
        password_confirmation: passwordMatch(values.password)(values.password_confirmation),
    }
    
    return removeEmpty(errors)
}

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import { _ } from 'helpers/lang'
import { authActions } from 'stores/auth/auth.actions' 
import { alertActions } from 'stores/alert/alert.actions'
import { isOnline } from 'helpers/data'
import { auth } from 'helpers/auth'
import LoginForm from './LoginForm/LoginForm'
import { HistoryType } from 'types/ReactRouter.type'
import Layout from 'components/Layout/Layout'
import Modal from 'components/Modal/Modal'
import { selectSettings } from 'stores/settings/settings.selectors'
import Markdown from 'components/Markdown/Markdown'
import Title from 'components/Title/Title'
import Inner from 'components/Inner/Inner'
import ForgotPasswordForm from 'app/ForgotPasswordForm/ForgotPasswordForm'

const mapStateToProps = (state, { history }) => {
    return {
        history,
        settings: selectSettings(state)
    }
}

const mapDispatchToProps = {
    error: alertActions.error,
    logout: authActions.logout,
    logoutAllDevices: authActions.logoutAllDevices,
    login: authActions.login,
    sendForgotPasswordMail: authActions.sendForgotPasswordMail,
}

const propTypes = {
    history: HistoryType.isRequired,
    error: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    login:PropTypes.func.isRequired,
    logoutAllDevices: PropTypes.func.isRequired,
    requestLogoutAllDevices: PropTypes.bool,
}

const defaultProps = {
    requestLogoutAllDevices: false,
}

class Login extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            modalState: false
        }

        this.content = this.props.settings.find(element => (element && element.slug === 'password_content'))
        if(this.content) this.content = this.content.value
    }

    componentDidMount(){
        if (auth()) {
            if (this.props.requestLogoutAllDevices) {
                this.props.logoutAllDevices()
            } else {
                this.props.logout()
            }
        }
    }

    handleSubmit = ({ email, password }, { setSubmitting }) => {
        if (!isOnline()) window.location.reload() // in case the form is still used when offline
        
        if (email && password) {
            setSubmitting(true)

            this.props.login(email, password)
                .then(success => {
                    if (!success) setSubmitting(false)
                })
        }
    }

    handleForgotPasswordSubmit = ({ email }, { setSubmitting }) => {
        if (email) {
            setSubmitting(true)
            this.props.sendForgotPasswordMail(email)
                .then(success => {
                    if (!success) setSubmitting(false)
                })

            this.setState({modalState:false})    
        }
    }

    handleForgotPasswordClick = () => {
        this.setState({modalState:true})
    }

    render() {
        return <Layout>
            <Inner mb={0}>
                <Title>{_('auth.loginTitle')}</Title>
                <LoginForm error={this.props.error} handleSubmit={this.handleSubmit} forgotPasswordClick={this.handleForgotPasswordClick} />
            </Inner>
            {this.state.modalState && <Modal closeAction={() => { this.setState({ modalState: false }) }}>
                <Typography variant="h1">
                    {_('auth.forgotPasswordTitle')}
                </Typography>
                <Box mt={2}>
                    <Markdown source={this.content} />
                </Box>
                <ForgotPasswordForm handleSubmit={this.handleForgotPasswordSubmit} />
            </Modal>}
        </Layout>
    }
}

Login.propTypes = propTypes
Login.defaultProps = defaultProps

export default connect(mapStateToProps, mapDispatchToProps)(Login)

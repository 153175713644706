import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import ReactVivus from 'react-vivus'

import { useStyles } from './AddStamp.styles'
import Layout from 'components/Layout/Layout'
import loading from 'assets/svg/loading.svg'
import { destinationRouteActions } from 'stores/destinationRoute/destinationRoute.actions'

AddStamp.propTypes = {
    update: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
    update: destinationRouteActions.update,   
}

function AddStamp({ update, match }) {
    const classes = useStyles()

    useEffect(() => {
        setTimeout(() => {
            update(match.params.hashid)
        }, 1000)
    }, [])

    return (
        <Layout>
            <Grid 
                container
                justify="center"
                alignItems="center"
                className={classes.container}
            >
                <Grid item xs={12} align="center">
                    <ReactVivus
                        id="loading"
                        option={{
                            file: loading,
                            duration: 150,
                            animTimingFunction: 'EASE',
                            type: 'sync',
                        }}
                        style={{ height: '150px', width: '150px' }}
                        callback={(vivus) => {
                            if (vivus.getStatus() === 'start')
                                vivus.play(1)
                            if (vivus.getStatus() === 'end')
                                vivus.play(-1)
                        }}
                    />
                </Grid>
            </Grid>
        </Layout>
    )
}

export default connect(null, mapDispatchToProps)(AddStamp)
import { placeDistanceConstants } from 'stores/placeDistance/placeDistance.constants'

const initialState = []

// all state changes manipulations happen here
export function placeDistanceStore(state = initialState, { type, payload }) {
    switch (type) {       
        case placeDistanceConstants.UPDATE_ALL:
            return payload
        default:
            return state
    }
}

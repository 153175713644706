import { init, Integrations } from '@sentry/browser'
import { env } from 'environments/base'

// Sentry Initialization
if (env.sentryDSN) {
    init({
        ignoreErrors: [/.*(gtag\/js).*/],
        dsn: env.sentryDSN,
        environment: env.environment,
        integrations: [new Integrations.Breadcrumbs({ console: false })],
    })
}
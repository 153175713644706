
import { createSelector } from 'reselect'

export class TagsSelector {
    static selectTags(tags) {
        return tags
    }

    static selectTagById(tags, id) {
        return tags.find((d) => d.id === id)
    }
}

export const selectTags = createSelector(
    (state) => state.tags,
    TagsSelector.selectTags
)

export const selectTagById = createSelector(
    (state) => state.tags,
    (state, id) => id,
    TagsSelector.selectTagById
)

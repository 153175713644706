import { authConstants } from 'stores/auth/auth.constants'
import { destinationRouteConstants } from 'stores/destinationRoute/destinationRoute.constants'

const initialState = []

// all state changes manipulations happen here
export function destinationRouteStore(state = initialState, { type, payload }) {   
    switch (type) {
        case destinationRouteConstants.LIST_SUCCESS:
            return payload.data
        
        case destinationRouteConstants.ADD_STAMP_SUCCESS:
            return [payload.data]

        case destinationRouteConstants.SEND_REWARD_FORM_SUCCESS:
            return [payload.data]    

        case authConstants.LOGOUT_DEVICES_SUCCESS:
        case authConstants.LOGOUT_SUCCESS:
            return []
        default:
            return state
    }
}

import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    webcamContainer: {
        position: 'relative',
    },
    mask: {
        position: 'absolute',
        bottom: theme.spacing(2.1),
        right: theme.spacing(1.8),
        zIndex: 9999
    }
}))

import { httpClient } from 'helpers/http'
//import fakeData from './data.json'

export const settingsService = {
    list,
    show,
}

const resource = '/settings'

function list(filters = undefined) {
    return httpClient.GET(resource + (filters ? filters : ''))
    //return Promise.resolve({ data: fakeData })
}

function show(number) {
    return httpClient.GET(`${resource}/${number}`)
}


/*function create(settings) {
    const data = JSON.stringify(settings)
    return httpClient.POST(resource, { data })
}

function update(settings) {
    const data = JSON.stringify(settings)
    return httpClient.PUT(`${resource}/${settings.id}`, { data })
}*/

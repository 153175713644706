import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        paddingBottom: '60%',
        width: '100%',
        bottom: theme.spacing(7.5),
        '& > img': {
            position: 'absolute',
            objectFit: 'cover',
            height: '100%',
            width: '100%'
        }
    }  
}))

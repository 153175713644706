import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    menu: {
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: 103,
        padding: '7rem 3rem 2rem',
        width: 355,
        height: '100vh',
        overflow: 'auto',
        boxSizing: 'border-box',
        background: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            left: '0',
            borderLeft: '20px solid',
            borderColor: theme.palette.primary.darker,
        }, 
        [theme.breakpoints.down('340')]: {           
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),            
        },     
    },
    menubutton: {
        margin: '0.5rem 0'
    },
    menulist: {
        listStyle: 'none',
        margin: '0 0 1rem',
        padding: '0'
    },
    menulistItem: {
        fontSize: '1rem',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        padding: '1rem 0',
        borderTop: '1px solid rgba(255, 255, 255, 0.2)',        
        '&:last-child' : {
            borderBottom: '1px solid rgba(255, 255, 255, 0.2)'
        }
    },
    menulistItemLink: {   
        display: 'block',     
        textDecoration: 'none',
        
    },
    buttonList: {
        margin: '0 0 0.5rem'
    },
    lienSmall: {
        fontSize: '0.75rem',
        textDecoration: 'underline',
        fontWeight: '400',
        '&:hover': {
            textDecoration: 'underline'
        }
    },    
    bottomList: {
        position: 'absolute',
        bottom: '2rem',
        left: 0,
        right: 0,
        margin : 'auto',
        padding: '0 3rem 0',
        '@media (max-height:615px)' : {            
            position: 'relative',
            padding: 0,
            bottom: 'inherit',
        }
    }
}))

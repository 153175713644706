import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    wrapper: {    
        width: '100%',
        position: 'relative'   
    },
    appbar: {
        color: 'white',
        background: theme.palette.primary.darker,
        padding: '0.8rem 0',
        zIndex: 100
    },
    toolbar: {
        padding: '0 1.5rem'       
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        marginRight: theme.spacing(2),
    },
    welcome: {
        fontWeight: '500',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',   
        maxWidth: '85%'
    }
}))

import React from 'react'
import Typography from '@material-ui/core/Typography'

const HeadingRenderer = ({ level, children }) => <Typography
    paragraph
    variant={`h${level}`}
    component={`h${level}`}
>{children}</Typography>

export default HeadingRenderer

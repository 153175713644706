import { userInfoService } from 'stores/userInfo/userInfo.service'
import { userInfoConstants } from 'stores/userInfo/userInfo.constants'
import { alertActions } from 'stores/alert/alert.actions'
import { _ } from 'helpers/lang'

export const userInfoActions = {
    list,
    show,
    update,
}

function list(filters = undefined) {
    return dispatch => {
        dispatch(request(filters))
        return userInfoService.list(filters).then(
            data => {
                dispatch(success(data))
                return true
            },
            error => {
                dispatch(failure(error))
                return false
            }
        )
    }

    function request(filters) {
        return { type: userInfoConstants.LIST_PENDING, filters }
    }

    function success(payload) {
        return { type: userInfoConstants.LIST_SUCCESS, payload }
    }

    function failure(error) {
        return { type: userInfoConstants.LIST_FAILURE, error }
    }
}

function show(id) {
    return dispatch => {
        dispatch(request(id))
        return userInfoService.show(id).then(
            payload => {
                dispatch(success(payload))
                return true
            },
            error => {
                dispatch(alertActions.error(error))
                dispatch(failure(error))
                return false
            }
        )
    }

    function request(id) {
        return { type: userInfoConstants.SHOW_PENDING, id }
    }

    function success(payload) {
        return { type: userInfoConstants.SHOW_SUCCESS, payload }
    }

    function failure(error) {
        return { type: userInfoConstants.SHOW_FAILURE, error }
    }
}

function update(data) {
    return dispatch => {
        dispatch(request(data))
        return userInfoService.update(data).then(
            payload => {
                dispatch(alertActions.success(_('alert.userInfo_200')))
                dispatch(success(payload)) 
                return true
            },
            error => {            
                dispatch(alertActions.error(error))
                dispatch(failure(error))
                return false
            }
        )
    }

    function request() {
        return { type: userInfoConstants.UPDATE_PENDING, data }
    }

    function success(payload) {
        return { type: userInfoConstants.UPDATE_SUCCESS, payload }
    }

    function failure(error) {
        return { type: userInfoConstants.UPDATE_FAILURE, error }
    }
}

import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        fontWeight: '600',
        textDecoration: 'none',
        transition: 'all 200ms ease-out',
        //color: (props) => theme.palette[props.color].main
        '&:hover:not(.disabled)' : {
            color: theme.palette.primary.darker,
            textDecoration: 'none'
        },
        '&.disabled' : {
            opacity: 0.4,
            cursor: 'not-allowed',
            textDecoration: 'none'
        }
    },
    icon: {
        fontSize: '1.5em',
        verticalAlign: 'text-bottom',
        margin: theme.spacing(0, 0.25),
    },
}))
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({    
    root: {
        backgroundColor: '#3B579D',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        }
    }    
}))
import isEmpty from 'lodash/isEmpty'

import store from 'stores/root-store'
import { destinationActions } from 'stores/destination/destination.actions'
import { settingsActions } from 'stores/settings/settings.actions'
import { tagActions } from 'stores/tag/tag.actions'
import { tripActions } from 'stores/trip/trip.actions'

export const isDataExpired = () => {
    const now = window.moment().format('X')
    const updated_at = JSON.parse(localStorage.getItem('updated_at'))
    const expires_at = updated_at + 60 * 5

    const expired = now >= expires_at
    return expired
}

export const isOnline = () => {
    const offline = localStorage.getItem('offline') === "true"
    return !offline
}

const shouldFetchData = () => {
    const {
        destinations,
        settings,
        tags,
    } = store.getState()
    
    return (isOnline()) && (
        isDataExpired()
        || isEmpty(destinations)
        || isEmpty(settings)
        || isEmpty(tags)
    )
}

export const fetchData = (force = false) => {
    if(shouldFetchData() || force) {
        store.dispatch(destinationActions.list())        
        store.dispatch(settingsActions.list())
        store.dispatch(tagActions.list())
        store.dispatch(tripActions.list())
        
        localStorage.setItem('updated_at', window.moment().format('X'))

        // if (force) {
        //     store.dispatch(alertActions.success('Données rafraîchies !'))
        // }

        return true
    }
    
    return false
}

import { destinationConstants } from 'stores/destination/destination.constants'

const initialState = []

// all state changes manipulations happen here
export function destinationStore(state = initialState, { type, payload }) {
    switch (type) {
        case destinationConstants.LIST_SUCCESS:
            return payload.data

        case destinationConstants.SHOW_SUCCESS:
        case destinationConstants.CREATE_SUCCESS:
        case destinationConstants.UPDATE_SUCCESS:
            return [
                ...state.filter(destination => destination.id !== payload.data.id), // remove old entry if it exists
                payload.data,
            ]
        default:
            return state
    }
}

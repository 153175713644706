import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Checkbox from '@material-ui/core/Checkbox'
import clsx from 'clsx'

import { _ } from 'helpers/lang'
import { useStyles } from './MapFilters.styles'
import Button from 'components/Button/Button'

Map.MapFilters = {
    filters: PropTypes.array.isRequired,
}

export function MapFilters({ className, filters, filterAction, closeAction, ...props}) {
    const classes = useStyles()    

    return (
        <Box className={clsx(className, classes.root)} align="center">
            <Box className={classes.container}>
                <CloseIcon className={classes.close} onClick={closeAction} />       
                <Box mb={2}>
                    <Grid 
                        container
                        justify="flex-start"
                        alignItems="center"
                    >
                        <Grid item xs={1} align="left"></Grid>
                        <Grid item xs={10} align="left" >
                            <Typography variant="h4" color="secondary">
                                {_('app.filters')}
                            </Typography>
                            {filters.map((item, i) => (
                                <Box key={i} mt={1.75}>
                                    <Button                                         
                                        className={classes.filter}
                                        onClick={(e) => filterAction(e, item)}
                                    >
                                        <img alt={item.slug} src={item.icon} className={classes.filterIcon} />
                                        {item.name}
                                        <Checkbox
                                            color="secondary"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            className={classes.filterCheck}
                                            checked={item.checked}
                                        />
                                    </Button>
                                </Box> 
                            ))}                            
                        </Grid>                    
                    </Grid>
                </Box>
            </Box>                        
        </Box>
    )
}

export default MapFilters

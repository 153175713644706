import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import InputMask from 'react-input-mask'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import EmailIcon from '@material-ui/icons/Email'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'

import { _ } from 'helpers/lang'
import { isOnline } from 'helpers/data'
import { validate } from './AccountForm.helpers'
import { useStyles } from './AccountForm.styles'
import Button from 'components/Button/Button'
import { useAuth } from 'hooks/useAuth'
import { UserInfoType } from 'types/UserInfo.type'
import { removeEmpty } from 'utils/functions'

AccountForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    userInfo: UserInfoType,
}

AccountForm.defaultProps = {
    autoDisabled: false
}

const defaultValues = {
    first_name: '',
    last_name: '',
    address: '',
    city: '',
    postal_code: '',
    state: 'QC',
    country: 'CA',
    phone: '',
    email: '',
}

function AccountForm({ handleSubmit, userInfo, autoDisabled, ...props }) {
    const { user } = useAuth(true)
    const classes = useStyles()

    const [disabled, setDisabled] = React.useState(false)

    const initialValues = {
        ...defaultValues,
        ...removeEmpty(userInfo),
    }

    return (
        <Box {...props}>

            {isOnline()
        
                ? (<Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validate={validate}
                    onSubmit={handleSubmit}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                        } = props

                        if(isSubmitting){
                            setTimeout(() => {
                                setDisabled(true)
                            }, 20)
                        }else if(!autoDisabled){
                            setTimeout(() => {
                                setDisabled(false)
                            }, 1000)
                        }

                        return (
                            <>                                
                                <List>                                        
                                    <ListItem>
                                        <ListItemIcon><EmailIcon /></ListItemIcon>
                                        <ListItemText primary={user.email} />
                                    </ListItem>                                        
                                </List>                                

                                <Form className={classes.form}>                                    
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        type="text"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="first_name"
                                        label={_('field.first_name')}
                                        name="first_name"
                                        value={values.first_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.first_name && touched.first_name}
                                        helperText={errors.first_name && touched.first_name ? errors.first_name : undefined}
                                    />

                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        type="text"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="last_name"
                                        label={_('field.last_name')}
                                        name="last_name"
                                        value={values.last_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.last_name && touched.last_name}
                                        helperText={errors.last_name && touched.last_name ? errors.last_name : undefined}
                                    />

                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        type="text"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="address"
                                        label={_('field.address')}
                                        name="address"
                                        value={values.address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.address && touched.address}
                                        helperText={errors.address && touched.address ? errors.address : undefined}
                                    />

                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        type="text"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="city"
                                        label={_('field.city')}
                                        name="city"
                                        value={values.city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.city && touched.city}
                                        helperText={errors.city && touched.city ? errors.city : undefined}
                                    />

                                    <InputMask mask="a9a 9a9" value={values.postal_code.toUpperCase()} onChange={handleChange} onBlur={handleBlur}>
                                        {(inputProps) =>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                type="text"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="postal_code"
                                                label={_('field.postalCode')}
                                                name="postal_code"                                    
                                                error={errors.postal_code && touched.postal_code}
                                                helperText={errors.postal_code && touched.postal_code ? errors.postal_code : undefined}
                                                {...inputProps}
                                            />
                                        }
                                    </InputMask> 

                                    <InputMask mask="(999) 999-9999" value={values.phone} onChange={handleChange} onBlur={handleBlur}>
                                        {(inputProps) => 
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                type="text"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="phone"
                                                label={_('field.phoneNumber')}
                                                name="phone"                                         
                                                error={errors.phone && touched.phone}
                                                helperText={errors.phone && touched.phone ? errors.phone : undefined}
                                                {...inputProps}
                                            />
                                        }
                                    </InputMask>                                

                                    <Box mt={3} align="center">
                                        <Button
                                            type="submit"
                                            disabled={disabled}
                                            className='large'
                                        >{_('app.confirm')}</Button>
                                    </Box>                                   
                                </Form>                                
                            </>                            
                        )
                    }}
                </Formik>)

                : (<div>
                    <h2>{_('app.offline_title')}</h2>
                    <p>{_('app.offline_text')}</p>
                </div>)
            }
        </Box>
    )
}

export default AccountForm
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Layout from 'components/Layout/Layout'
import Inner from 'components/Inner/Inner'
import { selectDestinations } from 'stores/destination/destination.selectors'
import { DestinationType } from 'types/Destination.type'
import { DestinationRouteType } from 'types/DestinationRoute.type'
import { selectDestinationRoute } from 'stores/destinationRoute/destinationRoute.selectors'
import Title from 'components/Title/Title'
import DestinationsStamp from 'components/DestinationsStamp/DestinationsStamp'
import ModalReset from 'components/Modal/ModalReset/ModalReset'

const mapStateToProps = (state, { match }) => {
    return {
        destinations: selectDestinations(state),
        destinationRoute: selectDestinationRoute(state),        
    }
}

Stamps.propTypes = {
    destinations: PropTypes.arrayOf(DestinationType).isRequired,
    destinationRoute: DestinationRouteType.isRequired,
}

function Stamps({destinations, destinationRoute}) {
    const [modalState, setModalState] = React.useState(false)  

    if(!destinationRoute) return <Layout>Chargement...</Layout>
    
    const stamps = destinationRoute.stamps

    return (
        <Layout>
            <Inner>
                <Title backArrow={false}>Mes timbres</Title>
                <DestinationsStamp 
                    destinations={destinations} 
                    stamps={stamps} 
                    sended={destinationRoute.status > 1} 
                    completed={destinationRoute.status > 0} 
                    modalAction={() => {setModalState(true)}} 
                />                
            </Inner>
            {modalState && <ModalReset closeAction={() => {setModalState(false)}} />}
        </Layout>
    )
}

export default connect(mapStateToProps)(Stamps)
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',        
        height: 'calc(100% - 3.75rem)',
        position: 'fixed',
        top: 0,
        zIndex: '9997',
        backgroundColor: theme.palette.primary.darker,
        color: theme.palette.secondary.main,
        overflow: 'auto',
        display: 'flex',
        alignItems: 'center'
    },
    container: {
        backgroundColor: 'white',
        borderRadius: '8px',
        maxWidth: '425px',
        margin: 'auto',
        zIndex: 100,
        position: 'relative',
        overflow: 'hidden',
        color: theme.palette.primary.darker
    },
    heading: {
        position: 'relative',   
        height: '30vh',
        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '-120px',
            left: 0,
            right: 0,
            margin: 'auto',
            width: '169px',
            height: '169px',
            background: 'white',
            borderRadius: '50%'
        }
    },
    headingImg: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: '-1',
        top: 0,
        left: 0,
        objectFit: 'cover',
        objectPosition: 'center',
    },
    logo: {
        marginTop: '-3.5rem',
        filter: "brightness(0) saturate(100%) invert(43%) sepia(22%) saturate(866%) hue-rotate(159deg) brightness(97%) contrast(87%)"
    },
    close: {
        cursor: 'pointer',
        position: 'absolute',
        right: '1.5rem',
        top: '1.5rem',
    },
    bgImg: {
        width: '70%',
        maxWidth: 'calc(425px * 0.7)',
        position: 'absolute',
        zIndex: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        top: 'calc(33%)',
        transform: 'translate(0, -50%)',
        opacity: 0.20,
    }
}))
import { tripService } from 'stores/trip/trip.service'
import { tripConstants } from 'stores/trip/trip.constants'

export const tripActions = {
    list,
    updateCustom,    
    updatePremade,
}

function list() {
    return dispatch => {
        dispatch(request())
        return tripService.list().then(
            data => {
                dispatch(success(data))
                return true
            },
            error => {
                dispatch(failure(error))
                return false
            }
        )
    }

    function request() {
        return { type: tripConstants.LIST_PENDING }
    }

    function success(payload) {
        return { type: tripConstants.LIST_SUCCESS, payload }
    }

    function failure(error) {
        return { type: tripConstants.LIST_FAILURE, error }
    }
}

function updateCustom(trip) {    
    return { type: tripConstants.UPDATE_CUSTOM, payload:trip }
}

function updatePremade(trip) {    
    return { type: tripConstants.UPDATE_PREMADE, payload:trip }
}

import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        width: 'calc(100% - 2.5rem)',
        top: theme.spacing(2.5),
        zIndex: 99,
        //backgroundColor: '#ffffff'

        '&.map': {
            top: theme.spacing(2.5)
        }
    },
    container : {
        position: 'relative',
        backgroundColor: theme.palette.primary.darker,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1.25),
    },    
    close: {
        position: 'absolute',
        zIndex: 301,
        left: theme.spacing(0.5),
        top: theme.spacing(0.5),
        fontSize: '2rem',
        cursor: 'pointer',
        transition: 'all 200ms ease-out',
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.primary.light
        }
    },
    filter: {
        width: '100%',
        textAlign: 'left',
        justifyContent: 'flex-start',
        height: theme.spacing(6),
        fontSize: '0.75rem'
    },
    filterIcon: {
        marginRight: theme.spacing(2)
    },
    filterCheck: {
        position: 'absolute',
        right: theme.spacing(1)
    } 
}))

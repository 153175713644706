import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',        
        height: 'calc(100% - 3.75rem)',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '9997',
        backgroundColor: theme.palette.primary.darker,
        color: theme.palette.secondary.main,
        overflow: 'auto',
    },
    container: {
        backgroundColor: theme.palette.primary.main,
        minHeight: '100%',
        maxWidth: '800px',
        margin: 'auto',
        zIndex: 100,
        position: 'relative',
    },
    close: {
        cursor: 'pointer'
    },
    bgImg: {
        width: '70%',
        maxWidth: 'calc(800px * 0.7)',
        position: 'absolute',
        zIndex: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        top: -5,
        opacity: 0.20
    }
}))
import * as Sentry from '@sentry/browser'
import { Lang } from 'helpers/lang'

// tells Sentry which user is logged in
export const configureScope = user => {
    Sentry.configureScope((scope) => {
        scope.setTag("lang", Lang.getLang())
        scope.setTag("user_type", user ? user.type : 'Undefined')
        if(user) {
            scope.setUser(user)
        }
    })
}

import React from 'react'
import { withRouter } from 'react-router-dom'
import flattenDeep from 'lodash/flattenDeep'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'

import { alertActions } from 'stores/alert/alert.actions'
import { selectAlerts } from 'stores/alert/alert.selectors'
import AlertContent from './AlertContent'

const mapStateToProps = (state, router) => {
    return {
        alerts: selectAlerts(state),
    }
}

const mapDispatchToProps = { ...alertActions }

// todo proptypes
class Alert extends React.Component {
    mounted = false
    state = {
        message: '',
        variant: 'info',
        open: false,
    }

    componentDidMount() {
        this.mounted = true
        this.init()
    }

    componentWillUnmount() {
        this.mounted = false
    }

    componentDidUpdate() {
        this.init()
    }

    init() {
        const { alerts } = this.props

        // display the last alert in the array (the one we just received)
        if (alerts.length) {
            const key = alerts.length - 1
            const alert = alerts[key]

            const messages = this.makeMessages(alert)
        
            if (!this.mounted) return
            
            const makeButton = () => {
                this.setState({
                    open: true,
                    message: messages[0],
                    component: alert.component,
                    variant: alert.type,
                })
            }
            
            // if snackbar already visible, close it and wait before showing the new one
            if (this.state.open) {
                this.setState({ open: false }, () => setTimeout(makeButton, 200))
            } else {
                makeButton()
            }
            
            // clear the alerts stack
            this.props.clear()
        }
    }

    makeMessages = alert => {
        if (!alert) return ''
        let { message } = alert

        message = message.text ? message.text : message 

        // return message in array
        if (typeof message === 'string') {
            return [message]
        } else if (Array.isArray(message)) {
            return flattenDeep(message)
        }

        let objResult = flattenDeep(Object.values(message))
        return objResult
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        this.setState({
            open: false,
        })
    }

    render() {
        const { open, message, variant } = this.state

        // make buttons array from component
        // todo: allow direct usage of component in component
        // const buttons = component ? component.map((option, key) => {
        //     return <Button
        //         key={key}
        //         onClick={() => {
        //             history.push(option.url)
        //             this.handleClose()
        //         }}
        //         {...option}
        //     />
        // }) : []


        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={this.handleClose}
            >
                <AlertContent
                    onClose={this.handleClose}
                    variant={variant}
                    message={message}
                    // buttons={buttons}
                />
            </Snackbar>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Alert))

import { tripConstants } from 'stores/trip/trip.constants'

const initialState = {
    premade: [],
    custom: {
        id: 0,
        name: 'Ma route personnalisée',
        places:[], 
        totalKm: 0
    }
}

// all state changes manipulations happen here
export function tripStore(state = initialState, { type, payload }) {
    switch (type) {
        case tripConstants.LIST_SUCCESS:
            return { ...state, premade: payload.data }

        case tripConstants.UPDATE_CUSTOM:
            return { ...state, custom: payload }

        case tripConstants.UPDATE_PREMADE:
            return {
                ...state, premade: [
                    ...state.premade.filter((trip) => trip.id !== payload.id),
                    payload
                ]
            }

        default:
            return state
    }
}

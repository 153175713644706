import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import QrReader from 'react-qr-reader'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'

import { _ } from 'helpers/lang'
import { env } from 'environments/base'
import Layout from 'components/Layout/Layout'
import Inner from 'components/Inner/Inner'
import Title from 'components/Title/Title'
import { alertActions } from 'stores/alert/alert.actions'
import { links } from 'helpers/links'
import Markdown from 'components/Markdown/Markdown'
import ModalModalAutorizedBrowserAPI from 'components/Modal/ModalAutorizedBrowserAPI/ModalModalAutorizedBrowserAPI'

ScanStamp.propTypes = {
    alertError: PropTypes.func.isRequired,
    alertInfo: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
    alertError: alertActions.error,
    alertInfo: alertActions.info
}

function ScanStamp({ alertError, alertInfo, match, ...props }) {
    const history = useHistory()

    const [result, setResult] = React.useState(false)
    const [working, setWorking] = React.useState(true)

    const urlCheck = env.appUrl + 'stamp/scan/'

    useEffect(() => {
        if(match.params.hashid)
            handleScan(urlCheck+match.params.hashid)        
    }, [])
    
    const handleScan = data => {
        if (data) {          
            if (data.startsWith(urlCheck)) {
                setResult(data)
                history.push(links.stamp.add+'/'+data.replace(urlCheck, ''))
            } else {
                alertError(_('app.invalid_code'))
            }
        }
    }

    const handleError = err => {
        setWorking(false)
    }

    return (
        <Layout>
            <Inner>
                <Title backArrow={false}>{_('app.scan_code')}</Title>
            </Inner>                          
        
            <ModalModalAutorizedBrowserAPI service='camera' refuseCallback={() => {history.goBack()}}>
                {!result && working && <Box>
                    <QrReader
                        delay={600}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: '100%' }}
                    />
                </Box>}
                {!working && <Inner>
                    <Box>
                        <Markdown source={_('app.help_scan')} />
                    </Box>
                </Inner>}  
            </ModalModalAutorizedBrowserAPI>
        </Layout>
    )
}

export default connect(null, mapDispatchToProps)(ScanStamp)
import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'

import { _ } from 'helpers/lang'
import { links } from 'helpers/links'
import { useStyles } from './ResetPasswordForm.styles'
import { validate } from './ResetPasswordForm.helpers'
import Button from 'components/Button/Button'
import Link from 'components/Link/Link'

ResetPasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
}

function ResetPasswordForm({ handleSubmit, token, email }) {
    const classes = useStyles()

    return (
        <Box align="center">
            <Formik
                initialValues={{ password: '', password_confirmation: '', token, email }}
                validate={validate}
                onSubmit={handleSubmit}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                    } = props

                    const disabled = isSubmitting

                    return (
                        <Form className={classes.form}>

                            <input
                                type="hidden"
                                name="token"
                                value={values.token}
                            />

                            <input
                                type="hidden"
                                name="email"
                                value={values.email}
                            />

                            <TextField
                                variant="outlined"
                                type="password"
                                margin="normal"
                                required
                                fullWidth
                                id="password"
                                label={_('field.newPassword')}
                                name="password"
                                autoComplete="password"
                                autoFocus
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.password && touched.password}
                                helperText={errors.password && touched.password ? errors.password : undefined }
                            />

                            <TextField
                                variant="outlined"
                                type="password"
                                margin="normal"
                                required
                                fullWidth
                                id="password_confirmation"
                                label={_('field.password_confirmation')}
                                name="password_confirmation"
                                autoComplete="password_confirmation"
                                value={values.password_confirmation}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.password_confirmation && touched.password_confirmation}
                                helperText={errors.password_confirmation && touched.password_confirmation ? errors.password_confirmation : undefined }
                            />

                            <div className={classes.buttonlist}>
                                <Button
                                    type="submit"
                                    disabled={disabled}
                                    className='large'
                                >{_('auth.resetPasswordButton')}</Button>
                            </div>

                            <Grid container>
                                <Grid item xs>
                                    <Link className={classes.link} href={links.login}>
                                        {_('auth.loginButton')}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>
        </Box>
    )
}

export default ResetPasswordForm

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { getDistance } from 'geolib'

import { useStyles } from './MapLayout.styles'
import { _ } from 'helpers/lang'
import Layout from 'components/Layout/Layout'
import Inner from 'components/Inner/Inner'
import Map from 'components/Map/Map'
import { placeDistanceActions } from 'stores/placeDistance/placeDistance.actions'
import { selectDestinations, selectPlaces } from 'stores/destination/destination.selectors'
import { selectTags } from 'stores/tag/tag.selectors'
import { DestinationType } from 'types/Destination.type'
import { DestinationPlaceType } from 'types/DestinationPlace.type'
import { tripActions } from 'stores/trip/trip.actions'
import { TagType } from 'types/Tag.type'
import Button from 'components/Button/Button'
import DestinationsList from 'components/DestinationsList/DestinationsList'
import { selectTrips, selectCustomTrip } from 'stores/trip/trip.selectors'
import filtersIcon from 'assets/svg/filters.svg'
import MapFilters from './MapFilters/MapFilters'
import listIcon from 'assets/svg/list.svg'
import mapIcon from 'assets/svg/mapIcon.svg'
import BottomModal from 'components/BottomModal/BottomModal'
import store from 'stores/root-store'
import { TripType } from 'types/Trip.type'
import Markdown from 'components/Markdown/Markdown'
import ModalModalAutorizedBrowserAPI from 'components/Modal/ModalAutorizedBrowserAPI/ModalModalAutorizedBrowserAPI'
import { usePosition } from 'hooks/usePosition'
import { usePlaceDistance } from 'hooks/usePlaceDistance'
import { links } from 'helpers/links'

const mapStateToProps = (state, { match }) => {

    const trips = selectTrips(state)
    const tripActive = (match.params.slug ? trips.find((trip) => trip.id === parseInt(match.params.slug)): null)

    return {
        destinations: selectDestinations(state),
        places: selectPlaces(state),
        tags: selectTags(state),        
        customTrip: selectCustomTrip(state),
        tripActive
    }
}

MapLayout.propTypes = {
    destinations: PropTypes.arrayOf(DestinationType).isRequired,
    places: PropTypes.arrayOf(DestinationPlaceType).isRequired,
    tags: PropTypes.arrayOf(TagType).isRequired,
    filtersOpen: PropTypes.bool,
    trip: PropTypes.number,
    customTrip: TripType.isRequired
}

function MapLayout(props) {
    const { getPlaceDistance, placeDistances } = usePlaceDistance()

    const {tags, destinations, places, customTrip, tripActive, custom } = props 

    const classes = useStyles()

    const [onglet, setOnglet] = React.useState(0)
    const [filtersOpen, setFiltersOpen] = useState(false)
    const [filters, setFilters] = useState(tags.map(tag =>  ({ checked:true, ...tag })))
    const [hideTripInfo, setHideTripInfo] = useState(false)
    const [hideTripDesc, setHideTripDesc] = useState(true)
    const selectedTrip = (custom ? customTrip : tripActive)   
    
    const {
        latitude,
        longitude,       
        setEnabled,
    } = usePosition(false)   

    useEffect(() => { // Calculer distance entre position actuel et destinations        
        if(!latitude || !longitude) return

        let newDistances = []
        destinations.forEach((destination) => {

            destination.places.forEach((place) => {
                const distance = getDistance(
                    { latitude: latitude, longitude: longitude },
                    { latitude: place.map_lat, longitude: place.map_lng }    
                )
                newDistances.push({id: place.id, distance:distance})
            })
        })        
        store.dispatch(placeDistanceActions.updateAll(newDistances))

    }, [latitude, longitude])    

    const handleFilterClick = (e, item) => {
        e.preventDefault()
        
        setFilters((state) => { 
            return [
                ...state.map((f, i) => {
                    if(f.slug === item.slug) f.checked = !f.checked            
    
                    return f
                })
            ]
        })
    }

    let filtredDestinations = destinations.filter((destination) => {

        const activeFilters = filters.filter((filter) => {
            return filter.checked === true
        })

        var result = 0
        activeFilters.forEach((f) => {

            destination.places.forEach((place) => {                
                place.tags.forEach((tag) => {
                    if(f.slug === tag.slug)
                        result = 1
                })  
            }) 
                     
        })

        return (result === 1)
    })

    filtredDestinations.sort(function(a, b) {
        var textA = a.name.toUpperCase()
        var textB = b.name.toUpperCase()
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
    })

    let filtredPlaces = places.filter((place) => {

        const activeFilters = filters.filter((filter) => {
            return filter.checked === true
        })

        var result = 0
        activeFilters.forEach((f) => {            
            place.tags.forEach((tag) => {
                if(f.slug === tag.slug)
                    result = 1
            })                     
        })

        return (result === 1)
    })

    if(selectedTrip) { // Trip active           
        filtredPlaces = filtredPlaces.filter((place) => {
            var result = 0            
            selectedTrip.places.forEach((trip, i) => {  
                if(parseInt(trip.id) === parseInt(place.id)) {
                    place.order = i
                    result = 1
                }
            })            

            return (result === 1)
        })

        filtredPlaces.sort(function(a, b) {
            return (a.order < b.order) ? -1 : (a.order > b.order) ? 1 : 0
        })
    }

    let filtredMarker = []    
    filtredPlaces.map(place => (        
        filtredMarker.push({
            id: place.destination.id,
            placeId: place.id,
            map_lat: place.map_lat,
            map_lng: place.map_lng,
            text: place.destination.name,
            marker: destinations.find(destination => destination.id === place.destination.id),
            distance: (getPlaceDistance(place.id) ? getPlaceDistance(place.id).distance : null)
        })
    ))

    if(selectedTrip) { // Trip active      
        filtredDestinations = filtredMarker
    }else{        
        if(placeDistances.length > 0) {            
            filtredDestinations.map(destination => (
                destination.distance = (getPlaceDistance(destination.places[0].id) ? getPlaceDistance(destination.places[0].id).distance : null)
            ))

            filtredDestinations.sort(function(a, b) {
                return (a.distance < b.distance) ? -1 : (a.distance > b.distance) ? 1 : 0
            })
        }
    }

    const handleApiCallback = (totalKm) => { // Google Map API Callback   
        if(selectedTrip.totalKm === totalKm)   
            return false

        if(props.custom)
            store.dispatch(tripActions.updateCustom({...selectedTrip, totalKm}))        
        else
            store.dispatch(tripActions.updatePremade({...selectedTrip, totalKm}))        
    }
   
    return (
        <Layout>
            <ModalModalAutorizedBrowserAPI service='location' agreedCallback={() => {setEnabled(true)}}>                        
                <Inner pl={2.5} pr={2.5} className={clsx(classes.filterBtnContainer, (onglet === 0 ? 'map' : ''))}>
                    <Box>
                        <Grid container spacing={3} justify="space-between">
                            <Grid item xs={6} sm={4}>
                                <Button className={clsx(classes.filterBtn, (onglet === 0 ? 'map' : 'large'))} onClick={() => setFiltersOpen(true)}>
                                    <img className={classes.filterBtnIcon} alt={_('app.filters')} src={filtersIcon} />{_('app.filters')}
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={8}>
                                <Box align="right">
                                    <Button 
                                        color="secondary" 
                                        className={clsx(classes.listeBtn, 'shadow','large')} 
                                        onClick={() => setOnglet((onglet === 0 ? 1 : 0))}
                                    >
                                        <img 
                                            className={classes.filterBtnIcon} 
                                            alt={(onglet === 0 ? _('app.list') : _('app.map'))} 
                                            src={(onglet === 0 ? listIcon : mapIcon)} 
                                        />
                                        {(onglet === 0 ? _('app.list') : _('app.map'))}
                                    </Button>
                                </Box>                            
                            </Grid>
                        </Grid>                    
                        {filtersOpen === true && <MapFilters className={(onglet === 0 ? 'map' : '')} filters={filters} filterAction={handleFilterClick} closeAction={() => setFiltersOpen(false)} />}
                    </Box>
                </Inner>
                {onglet === 0 && <Map markers={filtredMarker} tags={tags} hasTrip={!!selectedTrip} apiCallback={handleApiCallback} markerClick={setHideTripInfo} />}
                {onglet === 1 && <Inner className={classes.list} pt={0}>
                    <DestinationsList allAddress={!!selectedTrip} destinations={filtredDestinations} />
                </Inner>}
                {selectedTrip && !hideTripInfo && 
                <BottomModal slideAction={() => {setHideTripDesc(!hideTripDesc)}}>
                    <Box 
                        align="left" 
                        onClick={() => {setHideTripDesc(!hideTripDesc)}}
                        className={classes.tripInfo}
                    >
                        <Grid container>
                            <Grid item xs={9}>
                                <Typography variant="h1" component="div" className={classes.tripTitle}>
                                    {selectedTrip.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Box align="right">
                                    <Typography variant="h1" component="div">
                                        {selectedTrip.totalKm} Km
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    {!hideTripDesc && <Box mt={3}>
                        <hr />
                        <Box mt={2} align="left">
                            {selectedTrip.desc && <Markdown source={selectedTrip.desc} />}
                            {selectedTrip.id <= 0 && <Box align="center">
                                <Button
                                    className='large'
                                    to={links.trip.add}                                
                                >
                                    { _('app.edit_route')}
                                </Button>
                            </Box>}
                        </Box>                    
                    </Box>}
                </BottomModal>}
            </ModalModalAutorizedBrowserAPI>
        </Layout>
    )
}

export default connect(mapStateToProps)(MapLayout)
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    item: {
        position: 'relative',
        boxShadow: "-2px 2px 10px rgba(0, 0, 0, 0.15);",
        opacity: 0.5,
        '&.completed': {
            backgroundColor: theme.palette.primary.darker,
            color: theme.palette.secondary.main,
            opacity: 1
        }
    },
    containerStampCount: {
        width: 90
    },
    congrat: {
        fontSize: '1.75rem',
        textTransform: 'none',
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(1.5)
    },
    btnReward: {
        marginTop: theme.spacing(2.5),
    }
}))

import { httpClient } from 'helpers/http'
//import fakeData from './data.json'

export const tripService = {
    list,
}

const resource = '/trip'

function list(filters = undefined) {
    return httpClient.GET(resource + (filters ? filters : ''))
    //return Promise.resolve({ data: fakeData })
}

import { settingsConstants } from 'stores/settings/settings.constants'

const initialState = []

// all state changes manipulations happen here
export function settingsStore(state = initialState, { type, payload }) {
    switch (type) {
        case settingsConstants.LIST_SUCCESS:
            return payload.data

        case settingsConstants.SHOW_SUCCESS:
        case settingsConstants.CREATE_SUCCESS:
        case settingsConstants.UPDATE_SUCCESS:
            return [
                ...state.filter(settings => settings.id !== payload.data.id), // remove old entry if it exists
                payload.data,
            ]
        default:
            return state
    }
}

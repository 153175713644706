import { createSelector } from 'reselect'

export class destinationRoutesSelector {
    static selectDestinationRoute(destinationRoutes) {
        return destinationRoutes.length > 0 ? destinationRoutes[0] : null
    }

    static selectDestinationRouteById(destinationRoutes, id) {
        return destinationRoutes.find((d) => d.id === id)
    }
}

export const selectDestinationRoute = createSelector(
    (state) => state.destinationRoutes,
    destinationRoutesSelector.selectDestinationRoute
)

export const selectDestinationRouteById = createSelector(
    (state) => state.destinationRoutes,
    (state, id) => id,
    destinationRoutesSelector.selectDestinationRouteById
)

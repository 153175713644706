
import { createSelector } from 'reselect'

export class settingsSelector {
    static selectSettings(settings) {
        return settings
    }

    static selectSettingsById(settings, id) {
        return settings.find((d) => d.id === id)
    }
}

export const selectSettings = createSelector(
    (state) => state.settings,
    settingsSelector.selectSettings
)

export const selectsettingsById = createSelector(
    (state) => state.settings,
    (state, id) => id,
    settingsSelector.selectSettingsById
)

import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    signature: {
        '& img': {
            marginTop: theme.spacing(1),
            maxWidth: '40%',
            '@media (max-width:615px)' : {            
                maxWidth: '75%',
            }
        }
    }
}))

import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useStyles } from './Topbar.styles'
import { _ } from 'helpers/lang'
import { useAuth } from 'hooks/useAuth'
import { ElevationScroll } from './Topbar.helpers'
import ToggleButton from './ToggleButton/ToggleButton'
import TopbarMenu from './TopbarMenu/TopbarMenu'
import Logo from 'assets/svg/logo.svg'
import Link from 'components/Link/Link'
import { selectUserInfo } from 'stores/userInfo/userInfo.selectors'


function mapStateToProps(state) {
    return {
        userInfo: selectUserInfo(state)     
    }
}

export function Topbar({greatings, userInfo, ...props}) {
    const classes = useStyles()
    const [openMenu, setOpenMenu] = useState(false)
    const ref = useRef()
    const { isAuthenticated } = useAuth()

    return (
        <div className={classes.wrapper} ref={ref}>
            <ElevationScroll {...props}>
                <AppBar classes={{ root: classes.appbar }}>
                    <Toolbar variant="dense" classes={{ root: classes.toolbar }}>
                        <div className={classes.title}>
                            <Grid container
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item xs={2} sm={1}>
                                    {greatings && <Link alt={_('app.home')} href="/"><img src={Logo} alt="Logo" /></Link>}
                                </Grid>
                                <Grid item xs={10} sm={11}>
                                    {isAuthenticated && greatings && userInfo && <Typography className={classes.welcome} variant="h3" component='div'>
                                        {_('app.welcome')} {userInfo.first_name}
                                    </Typography>}                                 
                                </Grid>
                            </Grid>                            
                        </div>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <Box style={{position:'relative'}}>
                <ToggleButton onClick={() => setOpenMenu(!openMenu)} active={openMenu} />        
                <TopbarMenu opened={openMenu} boundaryRef={ref} closeMenu={() => setOpenMenu(false)} />
            </Box>            
        </div>
    )
}

export default connect(mapStateToProps)(Topbar)

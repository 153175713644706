import { httpClient } from 'helpers/http'
//import fakeData from './data.json'

export const destinationService = {
    list,
    show,
}

const resource = '/destination'

function list(filters = undefined) {
    return httpClient.GET(resource + (filters ? filters : ''))
    //return Promise.resolve({ data: fakeData })
}

function show(number) {
    return httpClient.GET(`${resource}/${number}`)
}


/*function create(destination) {
    const data = JSON.stringify(destination)
    return httpClient.POST(resource, { data })
}

function update(destination) {
    const data = JSON.stringify(destination)
    return httpClient.PUT(`${resource}/${destination.id}`, { data })
}*/

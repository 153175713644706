import app from './app'
import auth from './auth'
import date from './date'
import field from './field'
import alert from './alert'
import validation from './validation'

const fr = {
    translation: {
        app,
        alert,
        auth,
        date,
        field,
        validation,
    },
}

export default fr

import React from 'react'
import clsx from 'clsx'
import FacebookIcon from '@material-ui/icons/Facebook'

import { _ } from 'helpers/lang'
import { useStyles } from './FacebookButton.styles'
import Button from '../Button'
import { env } from 'environments/base'
import store from 'stores/root-store'
import { authActions } from 'stores/auth/auth.actions'
import { alertActions } from 'stores/alert/alert.actions'
import FacebookLogin from './src/Facebook'

FacebookButton.defaultProps = {}

function FacebookButton(props) {
    const classes = useStyles(props)

    const handleCallback = ({ accessToken }) => {
        if (accessToken) {
            store.dispatch(authActions.loginFacebook(accessToken))
        } else {
            store.dispatch(alertActions.error('La connexion n\'a pas pu procéder.'))
        }
    }
    
    return (
        <FacebookLogin
            appId="437017677522732"
            autoLoad={false}
            isMobile={false}
            disableMobileRedirect={true}
            fields="email,first_name,last_name"
            scope="public_profile,email"
            redirectUri={env.appUrl}
            callback={handleCallback}
            render={(renderProps) => (
                <Button
                    className={clsx(classes.root, 'icon')}
                    onClick={renderProps.onClick}
                >
                    <FacebookIcon />
                    {_('auth.fbConnect')}
                </Button>
            )}
        />
    )
}

export default FacebookButton

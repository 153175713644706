import React from 'react'
import Box from '@material-ui/core/Box'

import { _ } from 'helpers/lang'
import Layout from 'components/Layout/Layout'
import Inner from 'components/Inner/Inner'
import Title from 'components/Title/Title'
import { useSettings } from 'hooks/useSettings'
import Markdown from 'components/Markdown/Markdown'

function PolicyTerms(props) {
    const { getSetting} = useSettings()

    return (
        <Layout>
            <Inner>
                <Title backArrow={true}>{_('app.policy_terms')}</Title>
                <Box pt={3} pb={3}>                    
                    <Markdown source={getSetting('policy_terms_content')} />            
                </Box>                
            </Inner>
        </Layout>
    )
}

export default PolicyTerms
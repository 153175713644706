import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'

import { _ } from 'helpers/lang'
import { useStyles } from './Modal.styles'
import CloseIcon from 'assets/svg/close.svg'
import { useSettings } from 'hooks/useSettings'

Modal.propTypes = {
    children: PropTypes.any,
    closeAction: PropTypes.func.isRequired
}

function Modal(props) {
    const {
        children,
        closeAction,
        className,
        ...restProps
    } = props
    const classes = useStyles()
    const { getSettingArray } = useSettings()

    const title0 = getSettingArray('home_img', 0) ? getSettingArray('home_img', 0).title : null
    const src0 = getSettingArray('home_img', 0) ? getSettingArray('home_img', 0).src : null

    return (
        <Box p={2} pt={3.5} pb={3.5} className={clsx(classes.root, className)} {...restProps}>
            <img alt={title0} className={classes.bgImg} src={src0} />
            <Box p={3} pt={4} pb={4} className={classes.container} align="right">
                <img src={CloseIcon} alt={_('app.close')} className={classes.close} onClick={closeAction} />
                <Box mt={3} align="center">
                    {children}
                </Box>                
            </Box>
        </Box>
    )
}

export default Modal

import { makeStyles } from '@material-ui/core/styles'

const markerWidth = 38
const markerHeight = 38

export const useStyles = makeStyles(theme => ({    
    root: {
        backgroundColor: '#ffffff',
        borderWidth: 2,
        borderColor: theme.palette.primary.dark,
        borderStyle: 'solid',        
        width: markerWidth,
        height: markerHeight,        
        borderRadius: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        transition: 'all 200ms ease-out',
        '&.marker': {
            position: 'absolute',
            left: -markerWidth / 2,
            top: -markerHeight / 2,
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.secondary.main
            },

            '&.order, &.distance': {
                fontWeight: '700',
                fontSize: theme.spacing(1.6),
                '&>img': {
                    height: theme.spacing(2.25),
                    position: 'absolute',
                }
            },
            '&.order': {
                '&>img': {
                    top: theme.spacing(-1.2)
                }
            },
            '&.distance': {                    
                width: 60,
                height: 32,
                borderRadius: 20,     
                '&>img': {
                    top: theme.spacing(-1.5)
                }           
            }
        },  
    },
    big: {
        width: 48,
        height: 48,
        '&>img': {
            height: 25
        }
    },
    completed: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main
    }
}))
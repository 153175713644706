import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { useStyles } from './ToggleButton.styles'
import MenuIcon from 'assets/svg/menu.svg'
import CloseIcon from 'assets/svg/close.svg'

ToggleButton.propTypes = {
    onClick: PropTypes.func.isRequired,
}

function ToggleButton(props) {
    const classes = useStyles(props)
    const className = clsx(classes.root)

    let toggleButton
    if (props.active)
        toggleButton = <img src={CloseIcon} alt="Close" />
    else
        toggleButton = <img src={MenuIcon} alt="Menu" />        

    return (
        <div className={className} onClick={props.onClick}>
            {toggleButton}
        </div>
    )
}

export default ToggleButton

import PropTypes from 'prop-types'

export const UserInfoType = PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    postal_code: PropTypes.string,
    phone: PropTypes.string,
})

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { _ } from 'helpers/lang'
import { links } from 'helpers/links'
import { isAuthenticated } from 'helpers/auth'
import { alertActions } from 'stores/alert/alert.actions'

const mapDispatchToProps = { 
    alert: alertActions.info,
}

const propTypes = {    
    alert: PropTypes.func.isRequired,
    redirect: PropTypes.bool,
    redirectPath: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    inverse: PropTypes.bool,

    // Base route component
    path: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    exact: PropTypes.bool,
    strict: PropTypes.bool,
    sensitive: PropTypes.bool,
    component: PropTypes.any,
}

const defaultProps = {
    redirect: false,
    redirectPath: links.login,
    message: _('alert.authError'),
    inverse: false,
}

// Only render if user is authenticated
// Can redirect user
// can be inverted to only display if unauthenticated
class PrivateRoute extends Component {

    updated = false

    // refresh the auth and fetch the full user data
    render() {
        const {
            alert,
            redirect,
            redirectPath,
            inverse,
            message,
            component: ChildComponent, // Alias Because Uppercase.
            ...rest
        } = this.props

        let invalid = !isAuthenticated()
        if (inverse) invalid = !invalid // switcharoo

        if (invalid) {
            if (redirect) {
                if (message) alert(message)
                return <Redirect to={redirectPath} />
            }
            return null
        }
        
        return  <Route {...rest} render={props => <ChildComponent {...props} /> } />
    }
}

PrivateRoute.propTypes = propTypes
PrivateRoute.defaultProps = defaultProps

const connectedPrivateRoute = connect(null, mapDispatchToProps)(PrivateRoute)
export { connectedPrivateRoute as PrivateRoute }

export const getCenter = (markersList) => {

    let totalLat = []
    let totalLng = []       
           
    if(markersList.length <= 0) return {lat: 48.53, lng: -71.715}

    markersList.forEach((marker) => {
        totalLat.push(parseFloat(marker.map_lat, 6))
        totalLng.push(parseFloat(marker.map_lng, 6))             
    })
    
    const lat = ((Math.min.apply(null, totalLat) + Math.max.apply(null, totalLat)) / 2)
    const lng = ((Math.min.apply(null, totalLng) + Math.max.apply(null, totalLng)) / 2)
    
    return { lat: lat, lng: lng }
}

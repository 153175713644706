import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    container: {
        textAlign: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.        
    },
}))

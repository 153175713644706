import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import { _ } from 'helpers/lang'
import Layout from 'components/Layout/Layout'
import Inner from 'components/Inner/Inner'
import Title from 'components/Title/Title'
import RegisterForm from './RegisterForm/RegisterForm'
import FacebookButton from 'components/Button/FacebookButton/FacebookButton'
import { authActions } from 'stores/auth/auth.actions'

const mapDispatchToProps = {
    register: authActions.register,  
}

Register.propTypes = {
    register: PropTypes.func.isRequired,
}

function Register({register, ...props}) {

    const handleSubmit = (data) => {
        return register(data)
    }

    return (
        <>
            <Layout>
                <Inner>
                    <Title>{_('auth.registration')}</Title>

                    <Box mt={3} align="center">
                        <Divider />
                    </Box>

                    <Box mt={4} mb={4} align="center">
                        <FacebookButton />
                    </Box>

                    <Box m={3} align="center">
                        <Typography variant="h4" components="p">
                            {_('app.orfillform')}
                        </Typography>
                    </Box>                    

                    <RegisterForm error={props.error} handleSubmit={handleSubmit} />                       
                </Inner>
            </Layout>            
        </>
    )
}

export default connect(null, mapDispatchToProps)(Register)
export const userInfoConstants = {
    LIST_PENDING: 'USERINFO/LIST_PENDING',
    LIST_SUCCESS: 'USERINFO/LIST_SUCCESS',
    LIST_FAILURE: 'USERINFO/LIST_FAILURE',

    SHOW_PENDING: 'USERINFO/SHOW_PENDING',
    SHOW_SUCCESS: 'USERINFO/SHOW_SUCCESS',
    SHOW_FAILURE: 'USERINFO/SHOW_FAILURE',
    
    UPDATE_PENDING: 'USERINFO/UPDATE_PENDING',
    UPDATE_SUCCESS: 'USERINFO/UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERINFO/UPDATE_FAILURE',   
}

import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(10, 0, 7),
        minHeight: '100%',
        width: '100%',
        maxWidth: '800px',
        margin: 'auto',
        boxSizing: 'border-box',
        '&.stickyFooterContainer': {
            paddingBottom: 'calc(60% + 3.5rem)',
            position: 'relative'
        },  
        '&.home': {
            maxWidth: '100%',
            height: '100vh'
        }        
    },
    '@global': {
        strong: {
            fontWeight: 'bold',
        },
    },    
}))
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        width: '100%',
        left: 0,
        bottom: theme.spacing(7.5),
        zIndex: 100,
        backgroundColor: '#ffffff',
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        boxShadow: '-2px 2px 10px rgba(0, 0, 0, 0.15)'
    },
    contain: {
        maxHeight: 'calc(100vh - 230px)',
        overflow: 'auto',
        '@media (max-height:615px)' : {            
            maxHeight: 'calc(90vh - 230px)',
        }
    },
    spacer: {
        width: '40px',
        height: '4px',
        backgroundColor: theme.palette.primary.main,
        opacity: '0.3',
        borderRadius: 4
    } 
}))
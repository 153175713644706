import { autorizedBrowserAPIsConstants } from 'stores/autorizedBrowserAPIs/autorizedBrowserAPIs.constants'

const initialState = [
    {
        "slug": "location",
        "title": "Votre position",
        "text": "Nous aimerions connaître votre position géographique. Ceci nous permettra de vous donner la distance qu'il y a entre vous et nos microbrasseries. Par la suite, vous devrez autoriser votre navigateur à utiliser votre position actuelle.",
        "lastPrompt": 0,
        "promptTimeout": 20000,
        "agreed": false,
    },
    {
        "slug": "camera",
        "title": "Votre caméra",
        "text": "L'utilisation de votre caméra est requise pour pouvoir scanner vos timbres. Vous devez accepter l'utilisation. Après avoir cliqué sur accepter, vous devrez également accepter dans la fenêtre de votre navigateur.",
        "lastPrompt": 0,
        "promptTimeout": 0,
        "agreed": false,
    }
]

// all state changes manipulations happen here
export function autorizedBrowserAPIsStore(state = initialState, { type, payload }) {
    switch (type) {
        case autorizedBrowserAPIsConstants.LIST_SUCCESS:
            return payload.data

        case autorizedBrowserAPIsConstants.SHOW_SUCCESS:
        case autorizedBrowserAPIsConstants.CREATE_SUCCESS:
        case autorizedBrowserAPIsConstants.UPDATE_SUCCESS:
            return [
                ...state.filter(autorizedBrowserAPIs => autorizedBrowserAPIs.id !== payload.data.id), // remove old entry if it exists
                payload.data,
            ]
        default:
            return state
    }
}

import { useSelector } from 'react-redux'
import { autorizedBrowserAPIsActions } from 'stores/autorizedBrowserAPIs/autorizedBrowserAPIs.actions'
import store from 'stores/root-store'

const mapState = (state) => ({ autorizedBrowserAPIs: state.autorizedBrowserAPIs })

export const useAutorizedBrowserAPIs = () => {
    const { autorizedBrowserAPIs } = useSelector(mapState)

    const getAutorizedBrowserAPIs = slug => {
        if(!autorizedBrowserAPIs) return null
        const autorizedBrowserAPI = autorizedBrowserAPIs.find(s => s.slug === slug)

        if(autorizedBrowserAPI)
            return autorizedBrowserAPI
        else
            return null
    }

    const setAutorizedBrowserAPIs = (api, agreed) => {
        if(!autorizedBrowserAPIs || !api) return null

        const now = new Date().getTime()        
        
        const newAutorizedBrowserAPIs = autorizedBrowserAPIs.map((service) => {
            
            if(service.slug === api.slug){
                service.lastPrompt = now
                service.agreed = agreed
            }
    
            return service    
        })

        store.dispatch(autorizedBrowserAPIsActions.update(newAutorizedBrowserAPIs))
    }

    return { autorizedBrowserAPIs, getAutorizedBrowserAPIs, setAutorizedBrowserAPIs}
}
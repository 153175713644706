export const links = {
    home: '/',
    contact: '/contact',
    how: '/how',
    login: '/login',
    logout: '/logout',
    logoutAllDevices: '/logout-all-devices',
    account: '/account',
    register: '/register',
    map: '/map',
    notfound: '/not-found',
    policy: '/policy-terms',
    password: {
        forgot: '/password/forgot',
    },
    stamp: {
        list: '/stamp',
        scan: '/stamp/scan',
        add: '/stamp/add',
    },
    trip: {
        list: '/trip',
        add: '/trip/add',
        show: slug => `/map/${slug}`,
    },
    example: {
        list: '/example',
        new: '/example/new',
        edit: slug => `/example/edit/${slug}`,
        show: slug => `/example/show/${slug}`,
    },
}

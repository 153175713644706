import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
//import { Link as BaseLink } from 'react-router-dom'
import MuiLink from '@material-ui/core/Link'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import AttachmentIcon from '@material-ui/icons/Attachment'

import { isOnline } from 'helpers/data'
import { env } from 'environments/base'
import { useStyles } from './Link.styles'
import { history } from 'stores/root-store'

Link.propTypes = {
    href: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary']),
    afterOnClick: PropTypes.func
}

Link.defaultProps = {
    color: 'primary',
    href: '/',
    onClick: (e, url, afterOnClick) => {
        history.push(url)  
        
        if(afterOnClick) 
            afterOnClick()
    }
}

function Link (props) {
    const { href, children, className, onClick, afterOnClick, ...restProps } = props
    const classes = useStyles()
    const classNames = clsx(classes.root, className)

    const internal = href.startsWith('/')
    const pdf = href.endsWith('.pdf')

    let url = href

    const handleOnClick = (e) => {
        e.preventDefault()
        onClick(e, url, afterOnClick)        
    }

    if (internal) {    
        url = href.replace(env.appUrl, '/')
        return <MuiLink 
            href={url}
            className={classNames} 
            onClick={(e) => handleOnClick(e, url, afterOnClick)} 
            {...restProps} 
        >
            {children}
        </MuiLink>
    } 

    return (
        <MuiLink 
            className={classNames} 
            href={url}   
            rel="noopener noreferrer" 
            target="_blank" 
            onClick={(e) => handleOnClick(e, url)} 
            {...restProps}
        >
            {!isOnline() && <LinkOffIcon classes={{ root: classes.icon }}/>}
            {pdf && <AttachmentIcon classes={{ root: classes.icon }}/>}
            {children}
        </MuiLink>
    )
}

export default Link

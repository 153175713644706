import { _ } from 'helpers/lang'
import { authService } from 'stores/auth/auth.service'
import { authConstants } from 'stores/auth/auth.constants'
import { alertActions } from 'stores/alert/alert.actions'
import { configureScope } from 'sentry/configureScope'
import { history } from 'stores/root-store'
import { links } from 'helpers/links'

export const authActions = {
    login,
    loginFacebook,
    logout,
    logoutAllDevices,
    register,
    sendForgotPasswordMail,
    sendConfirmationEmail,
    resetPassword,
}

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }))

        return authService.login(username, password).then(
            data => {
                dispatch(success(data))                
                if(data.is_new)
                    history.push(links.how)
                else
                    history.push(links.home)
                return true
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return false
            }
        )
    }

    function request(data) {
        return { type: authConstants.LOGIN_PENDING, data }
    }

    function success(payload) {
        return { type: authConstants.LOGIN_SUCCESS, payload }
    }
    
    function failure(error) {
        return { type: authConstants.LOGIN_FAILURE, error }
    }
}


function loginFacebook(token) {
    return dispatch => {
        dispatch(request())

        return authService.loginFacebook(token).then(
            data => {
                dispatch(success(data))                
                if(data.user.is_new)
                    history.push(links.how)
                else
                    history.push(links.home)
                return true
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return false
            }
        )
    }

    function request() {
        return { type: authConstants.LOGIN_FACEBOOK_PENDING }
    }

    function success(payload) {
        return { type: authConstants.LOGIN_FACEBOOK_SUCCESS, payload }
    }
    
    function failure(error) {
        return { type: authConstants.LOGIN_FACEBOOK_FAILURE, error }
    }
}

function logout(message = _('alert.loggedOut')) {
    return dispatch => {
        dispatch(request())

        return authService.logout().then(
            data => {
                configureScope(false) // tell sentry to forget auth user
                localStorage.removeItem('auth')
                dispatch(success(data))
                if (message) dispatch(alertActions.success(message))
                return true
            },
            error => {
                localStorage.removeItem('auth')
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return false
            }
        )
    }

    function request(data) {
        return { type: authConstants.LOGOUT_PENDING, data }
    }

    function success(payload) {
        return { type: authConstants.LOGOUT_SUCCESS, payload }
    }
    
    function failure(error) {
        return { type: authConstants.LOGOUT_FAILURE, error }
    }
}

function logoutAllDevices(message = _('alert.loggedOutAllDevices')) {
    return dispatch => {
        dispatch(request())

        return authService.logoutAllDevices().then(
            data => {
                configureScope(false) // tell sentry to forget auth user
                localStorage.removeItem('auth')
                dispatch(success(data))
                if (message) dispatch(alertActions.success(message))
                return true
            },
            error => {
                localStorage.removeItem('auth')
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return false
            }
        )
    }

    function request(data) {
        return { type: authConstants.LOGOUT_DEVICES_PENDING, data }
    }

    function success(payload) {
        return { type: authConstants.LOGOUT_DEVICES_SUCCESS, payload }
    }
    
    function failure(error) {
        return { type: authConstants.LOGOUT_DEVICES_FAILURE, error }
    }
}

function register(user) {
    return dispatch => {
        dispatch(request(user))

        return authService.register(user).then(
            data => {
                dispatch(success(data))
                history.push(links.home)
                dispatch(alertActions.success(_('alert.registerSuccess')))
                return true
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return false
            }
        )
    }

    function request(data) {
        return { type: authConstants.REGISTER_PENDING, data }
    }
    function success(payload) {
        return { type: authConstants.REGISTER_SUCCESS, payload }
    }
    function failure(error) {
        return { type: authConstants.REGISTER_FAILURE, error }
    }
}

function sendForgotPasswordMail(email) {
    return dispatch => {
        dispatch(request(email))

        return authService.sendForgotPasswordMail(email).then(
            data => {
                dispatch(success(data))
                dispatch(alertActions.success(data))
                history.push(links.login)
                return true
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return false
            }
        )
    }

    function request(data) {
        return { type: authConstants.SEND_PASSWORD_MAIL_PENDING, data }
    }

    function success(payload) {
        return { type: authConstants.SEND_PASSWORD_MAIL_SUCCESS, payload }
    }
    
    function failure(error) {
        return { type: authConstants.SEND_PASSWORD_MAIL_FAILURE, error }
    }
}

function sendConfirmationEmail(email) {
    return dispatch => {
        dispatch(request({ email }))

        return authService.sendConfirmationEmail(email).then(
            data => {
                dispatch(success(data))
                dispatch(alertActions.success(data))
                return true
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return false
            }
        )
    }

    function request(data) {
        return { type: authConstants.SEND_CONFIRMATION_MAIL_PENDING, data }
    }
    function success(payload) {
        return { type: authConstants.SEND_CONFIRMATION_MAIL_SUCCESS, payload }
    }
    function failure(error) {
        return { type: authConstants.SEND_CONFIRMATION_MAIL_FAILURE, error }
    }
}

function resetPassword(data) {
    return dispatch => {
        dispatch(request(data))

        return authService.resetPassword(data).then(
            data => {
                dispatch(success(data))
                dispatch(alertActions.success(data))
                history.push(links.login)
                return true
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return false
            }
        )
    }

    function request(data) {
        return { type: authConstants.RESET_PASSWORD_PENDING, data }
    }

    function success(payload) {
        return { type: authConstants.RESET_PASSWORD_SUCCESS, payload }
    }
    
    function failure(error) {
        return { type: authConstants.RESET_PASSWORD_FAILURE, error }
    }
}


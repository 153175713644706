import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    container: {
        height: 325,
        maxWidth: '800px',
        margin: 'auto',
        position: 'relative',
        marginTop: '-5rem',
        overflow: 'hidden',
        zIndex: 100,
        //pointerEvents: 'none',
        '&.alternate': {
            height: 'calc(100vh - 10.2rem)',
        }
    },
    imgLeft: {        
        objectFit: 'cover',
        position: 'absolute',
        top: 0,
        height: '100%',
        width: '100%',
        '&.alternate': {
            height: 325,
        }        
    },
    img: {
        objectFit: 'cover',
        position: 'absolute',
        bottom: 0,
        height: 'calc(100% - 5rem)',
        width: '100%',
        '&.alternate': {            
            height: 'calc(100vh - 15.40rem)',           
            zIndex: -1,
            objectPosition: 'right'
        } 
    },
    logo: {
        position: 'absolute',
        bottom: 0,
        left: '4.3333%',
        width: '54%',
        height: 'calc(100% - 5rem)',
        zIndex: 102,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&.alternate': {
            height: 242,
            bottom: 'inherit',
            top: 83,
        } 
    }
}))

import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'

import { useStyles } from './DestinationStamp.styles'
import stampIcon from 'assets/svg/marker.svg'


DestinationStamp.propTypes = {
    destinationId: PropTypes.number,
    order: PropTypes.number
}

DestinationStamp.defaultProps = {
}

function DestinationStamp(props) {
    const {
        alt,
        destinationId,
        className,
        action,
        big,
        stampText,
        completed,
        ...rest
    } = props

    const classes = useStyles(props)

    return (
        <Box
            className={clsx({[classes.big]: big, [classes.completed]: completed}, className, classes.root)}
            onClick={action} 
            {...rest}
        >
            <img alt={alt} src={stampIcon} />{(stampText ? stampText : '')}
        </Box>
    )
}

export default DestinationStamp

import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'
import Typography from '@material-ui/core/Typography'

import { configureScope } from './configureScope'
import { auth } from 'helpers/auth'
import { _, Lang } from 'helpers/lang'
import { isOnline } from 'helpers/data'
import Button from 'components/Button/Button'


export const ErrorBoundary = class extends Component {
    constructor(props) {
        super(props)
        this.state = { eventId: null }
        let user = auth() && auth().user

        if (user) {
            if (user.token) delete user.token
            configureScope(user)
        }
    }
    
    static getDerivedStateFromError(error) {
        return { hasError: true }
    }
    
    componentDidCatch(error, errorInfo) {
        Sentry.withScope((scope) => {
            scope.setExtra('isOnline', isOnline())
            scope.setExtra('updated_at', localStorage.getItem('updated_at'))
            scope.setExtras(errorInfo)
            const eventId = Sentry.captureException(error)
            this.setState({eventId})
        })
    }

    renderErrorPage() {
        return (
            <div>
                <Typography variant="h1">{_('app.report_title')}</Typography>
                <Button 
                    onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId, lang: Lang.getLang() })}
                >{_('app.report_link')}</Button>
            </div>
        )
    }

    render() {
        // if (this.state.hasError) {
        //     //render fallback UI
        //     return this.renderErrorPage()
        // }
            
        //when there's not an error, render children untouched
        return this.props.children
    }
}

import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        bottom: theme.spacing(7.5),
        height: 'calc(100% - 8.9rem)',
        zIndex: 0,
        left: 0
    },
    /*marker: {
        backgroundColor: '#ffffff',
        borderWidth: 2,
        borderColor: theme.palette.primary.dark,
        borderStyle: 'solid',
        width: 38,
        height: 38,        
        borderRadius: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        transition: 'all 200ms ease-out',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        }
    },*/
    filterBtn: {
        height: '2.5rem',
        borderRadius: '0.6rem',
        backgroundColor: theme.palette.primary.darker,
        position: 'absolute',
        left: theme.spacing(2),
        top: theme.spacing(2),
        zIndex: 200,
        textTransform: 'none'
    },
    filterBtnIcon: {
        marginRight: 5
    }
}))

import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({        
    item: {
        color: theme.palette.primary.main,
        fontSize: '0.70rem',
        margin: theme.spacing(0, 0.60),
        [theme.breakpoints.up('350')]: {
            margin: theme.spacing(0, 0.75),
            fontSize: '0.75rem',
        },
        [theme.breakpoints.up('400')]: {
            margin: theme.spacing(0, 1.5),
        },
        '&:first-child': {
            marginLeft: 0
        },
        '&:last-child': {
            marginRight: 0
        }
    },
    icon: {
        borderRadius: '100%',
        width: 50,
        height: 50,
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0.5, 0),
    }
}))
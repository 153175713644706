import { createBrowserHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { sentryMiddleware } from 'sentry/sentryMiddleware'
import { env } from 'environments/base'
import createRootReducer from './root-reducer'
import { loadStore, saveStore } from 'helpers/localStorage'

export const history = createBrowserHistory({ basename: env.basename })

function configureStore(initialState) {

    const middlewares = [
        routerMiddleware(history),
        thunkMiddleware,
        env.sentryDSN ? sentryMiddleware : null,
    ].filter(m => m !== null)

    const composeEnhancers = composeWithDevTools({ trace: env.debugMode }) // enable action tracing in dev

    const store = createStore(
        createRootReducer(history), // root reducer with router state
        initialState,
        composeEnhancers(applyMiddleware(...middlewares))
    )

    return store
}

const store = configureStore(loadStore())

// save specific state to locale storage
store.subscribe(() => {
    saveStore({
        examples: store.getState().examples,
        destinations: store.getState().destinations,
        destinationRoutes: store.getState().destinationRoutes,
        settings: store.getState().settings,
        autorizedBrowserAPIs: store.getState().autorizedBrowserAPIs,
        tags: store.getState().tags,
        trips: store.getState().trips,
        userInfo: store.getState().userInfo,
        placeDistances: store.getState().placeDistances,
    })
})

export default store

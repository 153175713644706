import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'

import { _ } from 'helpers/lang'
import Markdown from 'components/Markdown/Markdown'
import { useStyles } from './ModalFancy.styles'
import CloseIcon from 'assets/svg/close.svg'
import { useSettings } from 'hooks/useSettings'
import Logo from 'assets/svg/logoRoutesdesBieres.svg'

ModalFancy.propTypes = {
    closeAction: PropTypes.func.isRequired
}

function ModalFancy(props) {
    const {
        children,
        closeAction,
        className,
        ...restProps
    } = props
    const classes = useStyles()
    const { getSetting, getSettingArray } = useSettings()

    const title0 = getSettingArray('home_img', 0) ? getSettingArray('home_img', 0).title : null
    const src0 = getSettingArray('home_img', 0) ? getSettingArray('home_img', 0).src : null

    const title1 = getSettingArray('desktop_img', 0) ? getSettingArray('desktop_img', 0).title : null
    const src1 = getSettingArray('desktop_img', 0) ? getSettingArray('desktop_img', 0).src : null

    return (
        <Box p={2} pt={3.5} pb={3.5} className={clsx(classes.root, className)} {...restProps}>
            <img alt={title0} className={classes.bgImg} src={src0} />
            <Box className={classes.container} >
                <Box className={classes.heading}>
                    <img alt={title1} src={src1} className={classes.headingImg} />
                    <img src={CloseIcon} alt={_('app.close')} className={classes.close} onClick={closeAction} />
                </Box>

                <Box p={3} pt={4} pb={4}>
                    <Box className={classes.logo} align="center">
                        <img alt={_('app.add_stamp')} src={Logo} />
                    </Box>
                    <Box mt={5} align="center">
                        <Box mt={3} mb={3} pl={1} pr={1}>
                            <Markdown source={getSetting('desktop_content')} />
                        </Box>
                    </Box>
                </Box>        
            </Box>
        </Box>
    )
}

export default ModalFancy

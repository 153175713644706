import PropTypes from 'prop-types'

export const DestinationPlaceType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    municipality: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    map_lat: PropTypes.number.isRequired,
    map_lng: PropTypes.number.isRequired,
    phone: PropTypes.string.isRequired,
})

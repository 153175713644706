import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    bottombar: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 60,
        backgroundColor: theme.palette.primary.dark,       
        zIndex: 10,
        boderTop: '2px solid rgba(255, 255, 255, 0.12)',
        '&::before': {
            content: '""',
            display: 'block',
            width: '100%',
            height: '2px',
            backgroundColor: 'rgba(255, 255, 255, 0.12)',
            position: 'absolute',
            zIndex: 9999,
            top: '0',
            
        }
    },
    actionRoot: {
        zoom: 1,
        padding: 0,
        minWidth: '50px',
        borderLeft: '1px solid rgba(0,0,0,0.2)',
        transition: 'all 200ms linear',
        '&:first-child': {
            borderLeft: 'none',
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main,  
        }
    },
    actionWrapper: {
        position: 'absolute',
        top: '0.95rem',
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'flex-start'        
    },
    actionLabel: {
        display: 'flex',
        alignItems: 'center',
        height: '2.5em',
        lineHeight: '1em',
        color: 'white',
        fontSize: '0.58rem',
        fontWeight: '500',
        //textTransform: 'uppercase'
    },
    active: {
        background: theme.palette.primary.main,
    },
}))
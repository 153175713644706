import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import { _ } from 'helpers/lang'
import { isOnline } from 'helpers/data'
import Button from 'components/Button/Button'
import { useStyles } from './LoginForm.styles'
import { validate } from './LoginForm.helpers'
import Link from 'components/Link/Link'
import FacebookButton from 'components/Button/FacebookButton/FacebookButton'

LoginForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    forgotPasswordClick: PropTypes.func
}

function LoginForm({ handleSubmit, forgotPasswordClick }) {
    const classes = useStyles()

    const handleForgotPasswordClick = forgotPasswordClick

    return (
        <Box className={classes.container}>            

            {isOnline()
        
                ? (<Formik
                    initialValues={{ email: '', password: '', }}
                    validate={validate}
                    onSubmit={handleSubmit}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                        } = props

                        const disabled = isSubmitting

                        return (
                            <Form className={classes.form}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="email"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label={_('field.email')}
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.email && touched.email}
                                    helperText={errors.email && touched.email ? errors.email : undefined }
                                />

                                <TextField
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label={_('field.password')}
                                    type="password"
                                    id="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.password && touched.password}
                                    helperText={errors.password && touched.password ? errors.password : undefined }
                                />

                                <div className={classes.buttonlist}>
                                    <Button
                                        type="submit"
                                        disabled={disabled}
                                        className='large'
                                    >{_('auth.loginAction')}</Button>
                                </div>
                                <Grid container>
                                    <Grid item xs>
                                        <Link className={classes.link} onClick={() => {handleForgotPasswordClick()}}>
                                            {_('auth.forgotPasswordTitle')}
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Box pt={5} pb={5}>
                                    <hr />
                                </Box>
                                <FacebookButton />                                
                            </Form>
                        )
                    }}
                </Formik>)

                : (<div>
                    <h2>{_('app.offline_title')}</h2>
                    <p>{_('app.offline_text')}</p>
                </div>)
            }
        </Box>
    )
}

export default LoginForm

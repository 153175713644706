import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({        
    info: {
        '&>*': {
            margin: theme.spacing(0.5, 0)
        }
    }, 
    img: {
        maxWidth: '82.5%',
        maxHeight: '7.5rem',
        //filter: "brightness(0) saturate(100%) invert(12%) sepia(52%) saturate(493%) hue-rotate(153deg) brightness(93%) contrast(89%)"
    },
    toggleBtn: {
        cursor: "pointer",        
        fontSize: '1.65rem',        
        transition: 'all 200ms ease-out',
        color: theme.palette.primary.light,
        '&:hover': {
            color: theme.palette.primary.dark
        }
    },
    distance: {
        position: 'absolute',
        top: theme.spacing(-1.75),
        left: 0,
        right: 0,
        width: theme.spacing(9.5),
        height: theme.spacing(3.5),
        margin: 'auto',
        backgroundColor: theme.palette.secondary.light,
        border: '1px solid #E5E6E7',
        borderRadius: 20,
        fontSize: theme.spacing(1.5),
        fontWeight: 'bold',
        lineHeight: '1.5rem'
    },
    distanceLabel: {
        display: 'inline',
        fontWeight: 'bold',
    }
}))
import { useSelector } from 'react-redux'

const mapState = (state) => ({ placeDistances: state.placeDistances })

export const usePlaceDistance = () => {
    const { placeDistances } = useSelector(mapState)

    const getPlaceDistance = id => {
        const distance = placeDistances.find(s => s.id === id)

        if(distance)
            return distance
        else
            return null
    }

    return { placeDistances, getPlaceDistance}
}
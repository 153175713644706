export const checkPermissions = {
    location: () => {
        return new Promise((resolve, reject) => {        
            navigator.geolocation.getCurrentPosition(resolve, reject)
        })
    },
    camera: () => {
        return new Promise((resolve, reject) => {
            navigator.mediaDevices ?
                navigator.mediaDevices.getUserMedia(
                    { video: true }
                ).then(
                    resolve, reject
                ) :
                navigator.getUserMedia(
                    { video: true },
                    resolve, reject
                )
        })
    }
}
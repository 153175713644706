import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'

import { _ } from 'helpers/lang'
import Button from 'components/Button/Button'
import { useStyles } from './ForgotPasswordForm.styles'
import { validate } from './ForgotPasswordForm.helpers'

ForgotPasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
}

function ForgotPasswordForm({ handleSubmit }) {
    const classes = useStyles()

    return (
        <Formik
            initialValues={{ email: '' }}
            validate={validate}
            onSubmit={handleSubmit}
        >
            {props => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                } = props

                const disabled = isSubmitting

                return (
                    <Form className={classes.form}>
                        <TextField
                            variant="filled"
                            type="text"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={_('field.email')}
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.email && touched.email}
                            helperText={errors.email && touched.email ? errors.email : undefined }
                        />
                        
                        <Box mt={1}>
                            <Button
                                type="submit"
                                disabled={disabled}
                                className='large'
                                color='secondary'
                            >{_('auth.forgotPasswordButton')}</Button>
                        </Box>                               
                    </Form>
                )
            }}
        </Formik>
    )
}

export default ForgotPasswordForm

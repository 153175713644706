import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'

import { useStyles } from './StickyBottomImg.styles'

StickyBottomImg.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
}
function StickyBottomImg(props) {
    const { src, alt, ...restProps } = props
    const classes = useStyles()
       
    return <Box className={classes.root} {...restProps}>
        <img alt={alt} src={src} />
    </Box>    
}

export default StickyBottomImg

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'

import { _ } from 'helpers/lang'
import { authActions } from 'stores/auth/auth.actions'
import { selectAuth } from 'stores/auth/auth.selectors'
import { UserInfoType } from 'types/UserInfo.type'
import { userInfoActions } from 'stores/userInfo/userInfo.actions'
import { selectUserInfo } from 'stores/userInfo/userInfo.selectors'
import Layout from 'components/Layout/Layout'
import { UserType } from 'types/User.type'
import { history } from 'stores/root-store'
import { links } from 'helpers/links'
import Title from 'components/Title/Title'
import AccountForm from './AccountForm/AccountForm'
import Link from 'components/Link/Link'

function mapStateToProps(state) {
    return {
        user: selectAuth(state).user,
        userInfo: selectUserInfo(state)     
    }
}

const mapDispatchToProps = {
    sendForgotPasswordMail: authActions.sendForgotPasswordMail,
    updateUserInfo: userInfoActions.update
}

Account.propTypes = {
    user: UserType.isRequired,
    userInfo: UserInfoType,
    sendForgotPasswordMail: PropTypes.func.isRequired,
    updateUserInfo: PropTypes.func.isRequired,
}

function Account(props) {
    const [disabled, setDisabled] = useState(false)
    const { sendForgotPasswordMail, updateUserInfo, user, userInfo } = props

    const handleSubmit = (data) => {       
        return updateUserInfo(data)
    }

    return (
        <Layout container>
            <Title>{_('app.my_account')}</Title>

            <Box mt={3} mb={2} align="center">
                <Typography variant="h4">
                    {_('app.viewAccount')}
                </Typography> 
            </Box>

            <AccountForm handleSubmit={handleSubmit} userInfo={userInfo} mb={4} />

            <Divider />
            
            {user.has_password && <Box mt={3} align="center">
                <Link
                    className={clsx({disabled})}
                    disabled={disabled}
                    onClick={() => {  
                        if(disabled)                      
                            return false

                        sendForgotPasswordMail(user.email)
                        setDisabled(true)
                    }}
                >
                    {_('auth.requestPasswordReset')}
                </Link>                
            </Box>}

            <Box mt={3} align="center">
                <Link
                    onClick={() => history.push(links.logoutAllDevices)}
                >{_('auth.logoutAllDevices')}</Link>               
            </Box>            

        </Layout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)

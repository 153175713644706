import { destinationRouteService } from 'stores/destinationRoute/destinationRoute.service'
import { destinationRouteConstants } from 'stores/destinationRoute/destinationRoute.constants'
import { alertActions } from 'stores/alert/alert.actions'
import { _ } from 'helpers/lang'
import { history } from 'stores/root-store'
import { links } from 'helpers/links'

export const destinationRouteActions = {
    list,
    show,
    update,
    sendRewardForm,
    resetRoute
}

function list(filters = undefined) {
    return dispatch => {
        dispatch(request(filters))
        return destinationRouteService.list(filters).then(
            data => {
                dispatch(success(data))
                return true
            },
            error => {
                dispatch(failure(error))
                return false
            }
        )
    }

    function request(filters) {
        return { type: destinationRouteConstants.LIST_PENDING, filters }
    }

    function success(payload) {
        return { type: destinationRouteConstants.LIST_SUCCESS, payload }
    }

    function failure(error) {
        return { type: destinationRouteConstants.LIST_FAILURE, error }
    }
}

function show(id) {
    return dispatch => {
        dispatch(request(id))
        return destinationRouteService.show(id).then(
            payload => {
                dispatch(success(payload))
                return true
            },
            error => {
                dispatch(alertActions.error(error))
                dispatch(failure(error))
                return false
            }
        )
    }

    function request(id) {
        return { type: destinationRouteConstants.SHOW_PENDING, id }
    }

    function success(payload) {
        return { type: destinationRouteConstants.SHOW_SUCCESS, payload }
    }

    function failure(error) {
        return { type: destinationRouteConstants.SHOW_FAILURE, error }
    }
}

function update(hashid) {
    return dispatch => {
        dispatch(request(hashid))
        return destinationRouteService.update(hashid).then(
            payload => {
                dispatch(alertActions.success(_('alert.stamp_200')))
                dispatch(success(payload))                
                history.push(links.stamp.list)
                return true
            },
            error => {                

                switch(error.code){
                    case 404 : 
                        dispatch(alertActions.error(_('alert.stamp_404')))
                        break
                    case 409 : 
                        dispatch(alertActions.info(_('alert.stamp_409'))) 
                        break
                    default: dispatch(alertActions.error(error))
                }

                dispatch(failure(error))
                history.push(links.stamp.scan)
                
                return false
            }
        )
    }

    function request() {
        return { type: destinationRouteConstants.ADD_STAMP_PENDING, hashid }
    }

    function success(payload) {
        return { type: destinationRouteConstants.ADD_STAMP_SUCCESS, payload }
    }

    function failure(error) {
        return { type: destinationRouteConstants.ADD_STAMP_FAILURE, error }
    }
}

function sendRewardForm(data) {
    return dispatch => {
        dispatch(request(data))
        return destinationRouteService.sendRewardForm(data).then(
            payload => {
                dispatch(alertActions.success(_('alert.reward_200')))
                dispatch(success(payload))
                return true
            },
            error => {                
                dispatch(alertActions.error(error))
                dispatch(failure(error))
                return false
            }
        )
    }

    function request() {
        return { type: destinationRouteConstants.SEND_REWARD_FORM_PENDING }
    }

    function success(payload) {
        return { type: destinationRouteConstants.SEND_REWARD_FORM_SUCCESS, payload }
    }

    function failure(error) {
        return { type: destinationRouteConstants.SEND_REWARD_FORM_FAILURE, error }
    }
}

function resetRoute(data) {
    return dispatch => {
        dispatch(request(data))
        return destinationRouteService.resetRoute(data).then(
            payload => {
                dispatch(alertActions.success(_('alert.reset_200')))
                dispatch(success(payload))
                history.push(links.stamp.list)
                return true
            },
            error => {                
                dispatch(alertActions.error(error))
                dispatch(failure(error))
                return false
            }
        )
    }

    function request() {
        return { type: destinationRouteConstants.SEND_REWARD_FORM_PENDING }
    }

    function success(payload) {
        return { type: destinationRouteConstants.SEND_REWARD_FORM_SUCCESS, payload }
    }

    function failure(error) {
        return { type: destinationRouteConstants.SEND_REWARD_FORM_FAILURE, error }
    }
}
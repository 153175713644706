import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    buttonlist: {
        margin: theme.spacing(1, -1),
    },
    button: {
        padding: theme.spacing(2, 2),
        borderRadius: theme.spacing(1.5),
        fontWeight: 'bold',
        fontSize: '14px',
        '&.large': {
            width: '100%',            
            maxWidth: theme.spacing(50)
        },
        '&.icon': {
            '& svg': {
                marginRight: theme.spacing(1.5),
                fontSize: 30,
            }
        },
        '&.shadow': {
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)"
        }
    },
}))
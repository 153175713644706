import { createMuiTheme } from '@material-ui/core/styles'

const blue = {
    main : '#487996',
    light : '#6FB4E8',
    dark : '#344C5A',
    darker : '#1A2E37',
    contrastText: '#ffffff',
}

const white = {
    main : '#ffffff',
    dark : blue.light,
    contrastText: blue.darker,
}

export const theme = createMuiTheme({
    typography: {
        fontSize: 14,

        body1: {
            fontSize: 15,
        },
        
        fontFamily: [
            'Montserrat',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        
        h1: {
            fontSize: '1rem',
            fontWeight: 'bold',
            lineHeight: '1.4em',
            textTransform: 'uppercase',
            letterSpacing: '0.05em',
        },
        h2: {
            fontSize: '1rem',
            fontWeight: 'bold',   
            lineHeight: '1.3em',
            color: blue.main
        },
        h3: {
            fontSize: '1rem',
            fontWeight: 'normal',
            lineHeight: '1.3em', 
        },
        h4: {
            fontSize: '0.875rem',
            fontWeight: 'bold', 
            lineHeight: '1.3em',
        },       
        h5: {
            fontSize: '0.75rem',
            fontWeight: 'bold',  
            lineHeight: '1.3em',
            textTransform: 'uppercase',         
        },
        h6: {
            fontSize: '0.8125rem',
            fontWeight: '500',  
            lineHeight: '1.3em',           
        },
        subtitle1: {
            fontSize: '0.875rem',
            fontWeight: 'bold', 
            lineHeight: '1.3em',   
            textTransform: 'uppercase',          
        },
    },
    palette: {
        primary: blue,
        secondary: white,        
        text: {
            primary: blue.darker,
            secondary: blue.light
        },
        /*background: {
            default: "#EAEBEF"
        }*/
        // error,
        // warning,
        // success,
        // info,
    },
    overrides: {    
        MuiCssBaseline: {
            '@global': {
                body: {
                    minHeight: '-webkit-fill-available'
                }
            },
        },
        MuiDivider: {
            root: {
                margin: '0.5rem 0'
            }
        },
        MuiInputBase: {     
            input: {                
                color: blue.darker,
                backgroundColor: '#F4F4F4',
                borderRadius: 10,
                height: '1.7rem',
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 10,     
                height: '3rem',           
                '& $notchedOutline': {
                    borderColor: '#ECECEC',
                },
                '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                    borderColor: blue.light,                   
                },
                '&$focused $notchedOutline': {
                    borderColor: blue.light,
                    borderWidth: 1,
                },                
            }
        },
        MuiFilledInput: {            
            root: {                
                '&:after': {
                    display: 'none'
                },
                '&::before': {
                    display: 'none'
                },
            }
        },
        MuiInputLabel: {
            root: {
                color: blue.darker, 
                lineHeight: '1.65rem', 
                "&$shrink": { // increase the specificity for the pseudo class
                    color: blue.light
                },        
            }            
        },
    }
})

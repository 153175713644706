import React from 'react'
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'
import Webcam from "react-webcam"
import mergeImages from 'merge-images'

import { _ } from 'helpers/lang'
import { useStyles } from './Selfie.styles'
import Layout from 'components/Layout/Layout'
import Inner from 'components/Inner/Inner'
import Title from 'components/Title/Title'
import { alertActions } from 'stores/alert/alert.actions'
import Button from 'components/Button/Button'
import Image from 'components/Image/Image'
import Logo from 'assets/svg/logoRoutesdesBieres.svg'

Selfie.propTypes = {
    alert: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
    alert: alertActions.error
}

function Selfie({ alert, ...props }) {
    const classes = useStyles()
    const webcamRef = React.useRef(null)
    const [imgSrc, setImgSrc] = React.useState(null)

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot()        

        mergeImages([imageSrc, {src: Logo, x: (document.body.scrollWidth - 135), y:((window.innerHeight - 275) / 2) -185,  opacity: 1}])
            .then(b64 => {setImgSrc(b64)})

    }, [webcamRef, setImgSrc])

    const videoConstraints = {
        width: document.body.scrollWidth,
        height: ((window.innerHeight - 275) / 2),
        facingMode: "user"
    }

    return (
        <Layout>
            <Inner>
                <Title backArrow={false}>{_('app.take_selfie')}</Title>
            </Inner>
            <Box>
                {!imgSrc && <Box align="center" >
                    <Box className={classes.webcamContainer}>
                        <Webcam
                            audio={false}                        
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                        />                        
                        <Image alt="mask" src={Logo} className={classes.mask} />
                    </Box>
                    <Button onClick={capture}>Prendre la photo</Button>
                </Box>}
                {imgSrc && (
                    <Box align="center">
                        <Image alt="selfie" src={imgSrc} />
                        <Button onClick={() => {setImgSrc(null)}}>Recommencer</Button>
                    </Box>
                )}
            </Box>                        
        </Layout>
    )
}

export default connect(null, mapDispatchToProps)(Selfie)

import { createSelector } from 'reselect'

export class DestinationsSelector {
    static selectDestinations(destinations) {
        return destinations
    }

    static selectDestinationById(destinations, id) {
        return destinations.find((d) => d.id === id)
    }

    static selectPlaces(destinations) {
        const placesArray = []

        destinations.forEach((destination) => {
            const { places, ...restDestination } = destination
            // add destination attribute inside each place object
            const destinationPlacesData = places.map(place => ({ ...place, destination: restDestination }))
            
            placesArray.push(...destinationPlacesData)
        })

        return placesArray
    }
}

export const selectDestinations = createSelector(
    (state) => state.destinations,
    DestinationsSelector.selectDestinations
)

export const selectDestinationById = createSelector(
    (state) => state.destinations,
    (state, id) => id,
    DestinationsSelector.selectDestinationById
)

export const selectPlaces = createSelector(
    (state) => state.destinations,
    DestinationsSelector.selectPlaces
)

import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'

import Logo from 'assets/svg/logoRoutesdesBieres.svg'
import { _ } from 'helpers/lang'
import { useStyles } from './HomeSlider.styles'
import { useSettings } from 'hooks/useSettings'

HomeSlider.propTypes = {
    // 
}

function HomeSlider({alternate}) {
    const classes = useStyles()
    const { getSettingArray } = useSettings()

    const title0 = getSettingArray('home_img', 0) ? getSettingArray('home_img', 0).title : null
    const src0 = getSettingArray('home_img', 0) ? getSettingArray('home_img', 0).src : null
    const title1 = getSettingArray('home_img', 1) ? getSettingArray('home_img', 1).title : null
    const src1 = getSettingArray('home_img', 1) ? getSettingArray('home_img', 1).src : null

    return (
        <>
            <Grid container alignItems="stretch" className={clsx(classes.container, (alternate ? 'alternate' : ''))}>
                <Grid item xs={7}>
                    <Box style={{ position: 'relative', height: '100%' }}>
                        <img alt={title0} className={clsx(classes.imgLeft, (alternate ? 'alternate' : ''))} src={src0} />
                    </Box>
                </Grid>
                <Grid item xs={(alternate ? 12 : 5)}>    
                    <Box style={{ position: 'relative', height: '100%' }}>
                        <img alt={title1} className={clsx(classes.img, (alternate ? 'alternate' : ''))} src={src1} />
                    </Box>                
                </Grid>
                <Box bgcolor="primary.main" className={clsx(classes.logo, (alternate ? 'alternate' : ''))} >
                    <img alt={_('app.site_title')} src={Logo} />
                </Box>
            </Grid>                        
        </>                
    )
}

export default HomeSlider
import { httpClient } from 'helpers/http'
//import fakeData from './data.json'

export const destinationRouteService = {
    list,
    show,
    update,
    sendRewardForm,
    resetRoute
}

const resource = '/me/route'

function list(filters = undefined) {
    return httpClient.GET(resource + (filters ? filters : ''))
    //return Promise.resolve({ data: fakeData })
}

function show(number) {
    return httpClient.GET(`${resource}/${number}`)
}

function update(hashid) {
    return httpClient.POST(`${resource}/add/${hashid}`)
}

function sendRewardForm(data) {
    if(data.phone)
        data.phone = data.phone.replace(/[\s()-]/g,'')

    data = JSON.stringify(data)    
    return httpClient.POST(`${resource}/reward`, { data })
}

function resetRoute(data) {
    data = JSON.stringify(data)
    return httpClient.POST(`${resource}/reset`, { data })
}

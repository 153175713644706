import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { isBrowser } from "react-device-detect"

import { _ } from 'helpers/lang'
import { useAuth } from 'hooks/useAuth'
import Layout from 'components/Layout/Layout'
import Button from 'components/Button/Button'
import HomeSlider from './HomeSlider/HomeSlider'
import Inner from 'components/Inner/Inner'
import { links } from 'helpers/links'
import { useSettings } from 'hooks/useSettings'
import ModalFancy from 'components/Modal/ModalFancy/ModalFancy'

Home.propTypes = {
    // 
}

function Home(props) {
    const { isAuthenticated } = useAuth()
    const { getSetting } = useSettings()

    const [modalState, setModalState] = React.useState(true)   

    return (
        <Layout bgcolor="primary.darker" className="home" greatings={false}>
            <HomeSlider alternate={isAuthenticated} />
            <Inner bgcolor="primary.darker" color="white">
                {!isAuthenticated && (
                    <Box pb={2.5} pt={0.5} textAlign="center">
                        <Typography variant="h1">
                            {getSetting('home_content')}
                        </Typography>
                    </Box>
                )}

                <Grid container spacing={2} align="center">                   
                    {!isAuthenticated && (
                        <>
                            <Grid item xs={12}>
                                <Button className="large" to={links.register}>
                                    {_('app.begin')}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    className="large"
                                    color="secondary"
                                    to={links.login}
                                >
                                    {_('auth.loginButton')}
                                </Button>
                            </Grid>
                        </>
                    )}
                    {isAuthenticated && (
                        <Grid item xs={12}>
                            <Button
                                className="large"
                                color="secondary"
                                to={links.logout}
                            >
                                {_('app.logout')}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Inner>
            {isBrowser && modalState && <ModalFancy closeAction={() => {setModalState(false)}} />}
        </Layout>
    )
}

export default Home
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({    
    item: {
        position: 'relative',        
        overflow: 'hidden',
        height: '100%',    
        width: '100%',    
        borderRadius: theme.spacing(1.5),
        boxShadow: "-2px 2px 10px rgba(0, 0, 0, 0.15);",
        "&:last-child": {
            marginBottom: theme.spacing(1)
        }, 
        '&.custom': {
            backgroundColor: theme.palette.primary.darker,
            color: theme.palette.secondary.main,
        },
    },
    itemLink: {
        transition: 'all 200ms ease-out',
        cursor: 'pointer',    
        borderRadius: theme.spacing(1.5),  
        '&:hover': {
            opacity: '0.8'
        },
    },
    imgContainer: {
        width: '100%',
        height: '20vh',
        maxHeight: '173px',
        position: 'relative',        
    },
    img: {
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        objectFit: 'cover',
        position: 'absolute',        
    },
    editIcon: {
        position: 'absolute',
        right: 0,
        cursor: 'pointer',
        zIndex: 1,
        width: theme.spacing(10),
        height: '20vh',
        maxHeight: '173px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.secondary.main,
        borderTopRightRadius: theme.spacing(1.5),
        transition: 'all 200ms ease-out',
        '&:hover': {  
            backgroundColor: theme.palette.primary.main                   
        }
    }
}))

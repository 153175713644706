import { tagService } from 'stores/tag/tag.service'
import { tagConstants } from 'stores/tag/tag.constants'

export const tagActions = {
    list,
}

function list(filters = undefined) {
    return dispatch => {
        dispatch(request(filters))
        return tagService.list(filters).then(
            data => {
                dispatch(success(data))
                return true
            },
            error => {
                dispatch(failure(error))
                return false
            }
        )
    }

    function request(filters) {
        return { type: tagConstants.LIST_PENDING, filters }
    }

    function success(payload) {
        return { type: tagConstants.LIST_SUCCESS, payload }
    }

    function failure(error) {
        return { type: tagConstants.LIST_FAILURE, error }
    }
}

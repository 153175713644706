const staging = {
    environment: 'staging',
    debugMode: false,
    debugHttpCalls: false,
    basename: '/app',   
    appUrl: "https://app.routedesbieres.dev.arsenalweb.ca/app/",
    apiUrl: "https://app.routedesbieres.dev.arsenalweb.ca/api/v1",
    storageUrl: "https://app.routedesbieres.dev.arsenalweb.ca/storage/",
    sentryDSN: "https://2d097f4146de4adbb6cca1251a0387e8@o197233.ingest.sentry.io/5689559",
    gmapKey: "AIzaSyAcyBVRM7Bt6JE2E5m-RH9Dde-mOcOqXH4",
}

export default staging

import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import { _ } from 'helpers/lang'
import { useStyles } from './How.styles'
import Layout from 'components/Layout/Layout'
import Inner from 'components/Inner/Inner'
import Title from 'components/Title/Title'
import Button from 'components/Button/Button'
import { useSettings } from 'hooks/useSettings'
import { links } from 'helpers/links'

import DestinationStamp from 'components/Destination/DestinationStamp/DestinationStamp'
import Markdown from 'components/Markdown/Markdown'

function How(props) {
    const { getSetting } = useSettings()
    const classes = useStyles()

    if(!getSetting('how_instructions')) return null

    return (
        <Layout>
            <Inner>
                <Title backArrow={false}>{_('app.how')}</Title>
            </Inner>
            <Box className={classes.bg}>
                <Grid 
                    container 
                    justify="center"
                    alignItems="center"
                >
                    <Grid item>
                        <Box className={classes.stamp}>
                            <DestinationStamp big />
                        </Box>
                    </Grid>
                </Grid> 
            </Box>
            <Inner>                
                <Box mt={3} mb={3} align="center">                    
                    <Markdown source={getSetting('how_content')} />
                </Box>
                {getSetting('how_instructions').map((item, i) => (
                    <Box p={2} mb={1.5} key={i} className={classes.instruction}>
                        <Grid container alignItems="center" spacing={0}>
                            <Grid item xs={2}>
                                <img alt={item.title} src={item.icon} />
                            </Grid>
                            <Grid item xs={10}>
                                {item.text}
                            </Grid>
                        </Grid>                        
                    </Box>
                ))}
                <Box mt={3} align="center">
                    <Button
                        type="button"
                        className='large'
                        to={links.stamp.list}
                    >{_('app.begin')}</Button>
                </Box>                
            </Inner>      
        </Layout>
    )
}

export default How
const production = {
    environment: 'production',
    debugMode: false,
    debugHttpCalls: false,
    basename: '/app',
    appUrl: "https://app.routedesbieresdusaglac.com/app/",
    apiUrl: "https://app.routedesbieresdusaglac.com/api/v1",
    storageUrl: "https://app.routedesbieresdusaglac.com/storage/",
    sentryDSN: "https://2d097f4146de4adbb6cca1251a0387e8@o197233.ingest.sentry.io/5689559",
    gmapKey: "AIzaSyC8UEBYr8ZCw4bRJdWb00LukHr6etYEktg",
}

export default production

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import EditIcon from '@material-ui/icons/Edit'

import { _ } from 'helpers/lang'
import { useStyles } from './Trip.styles'
import Layout from 'components/Layout/Layout'
import Inner from 'components/Inner/Inner'
import Title from 'components/Title/Title'
import { selectTrips, selectCustomTrip } from 'stores/trip/trip.selectors'
import { TripType } from 'types/Trip.type'
import { links } from 'helpers/links'
import { history } from 'stores/root-store'
import { useSettings } from 'hooks/useSettings'

const mapStateToProps = (state, { match }) => {
    return {
        trips: selectTrips(state),
        customTrip: selectCustomTrip(state)
    }
}

Trip.propTypes = {
    trips: PropTypes.arrayOf(TripType).isRequired
}

function Trip({trips, customTrip, ...props}) {
    const classes = useStyles()
    const { getSetting, getSettingArray } = useSettings()

    const customImgTitle = getSettingArray('trip_custom_img', 0) ? getSettingArray('trip_custom_img', 0).title : null
    const customImg = getSettingArray('trip_custom_img', 0) ? getSettingArray('trip_custom_img', 0).src : null
    const customTripText = getSetting('trip_custom_desc') ? getSetting('trip_custom_desc').split("-----") : null
    const hasAllSettings = (customTripText && customImg && customImgTitle)
    
    const hisCustomCreated = (customTrip.places.length > 0 ? true : false)    

    const handleClick = (trip) => {
        history.push(links.trip.show(trip))
    }

    trips.sort(function(a, b) {
        var textA = a.name.toUpperCase()
        var textB = b.name.toUpperCase()
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
    })

    return (
        <Layout>
            <Inner>
                <Title backArrow={false}>{_('app.trips')}</Title>

                <Box mt={3}>
                    {_('app.trip_text')}
                </Box>

                <Box mt={3} mb={1}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            {hasAllSettings && <Box className={clsx(classes.item, 'custom')}>
                                {hisCustomCreated && <Box 
                                    className={classes.editIcon}
                                    onClick={() => {history.push(links.trip.add)}}
                                >
                                    <EditIcon fontSize='large' />
                                </Box>}
                                <Box
                                    className={classes.itemLink}
                                    align="center"                                   
                                    onClick={() => {
                                        if(!hisCustomCreated)
                                            history.push(links.trip.add)
                                        else
                                            handleClick('custom')
                                    }}
                                >
                                    <Box className={classes.imgContainer}>
                                        <img className={classes.img} alt={customImgTitle} src={customImg} />
                                    </Box>                            
                                    
                                    <Box p={3} pb={1}>
                                        <Box mb={1.5}>
                                            <Typography variant="h1">
                                                {getSetting('trip_custom_title')}
                                            </Typography>
                                        </Box> 

                                        <Box mb={2.5}>
                                            {customTripText[(hisCustomCreated ? 1 : 0)]}
                                        </Box>
                                        <ArrowForwardIcon />                                    
                                    </Box>                            
                                </Box>
                            </Box>}                                                       
                        </Grid>

                        {trips.map((trip, i) => (
                            <Grid item xs={12} sm={6} key={i}>
                                <Box                                    
                                    align="center"
                                    className={clsx(classes.item, classes.itemLink)}
                                    onClick={() => handleClick(trip.id)}
                                >
                                    <Box className={classes.imgContainer}>
                                        <img className={classes.img} alt={trip.name} src={trip.img} />
                                    </Box>                            
                                    
                                    <Box p={3} pb={2}>
                                        <Box mb={1.5}>
                                            <Typography variant="h1">
                                                {trip.name}
                                            </Typography>
                                        </Box> 

                                        {trip.resume}
                                    </Box>                            
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>                                
            </Inner>
        </Layout>
    )
}

export default connect(mapStateToProps)(Trip)
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import clsx from 'clsx'

import { _ } from 'helpers/lang'
import Button from 'components/Button/Button'
import Modal from '../Modal'
import Markdown from 'components/Markdown/Markdown'
import { useAutorizedBrowserAPIs } from 'hooks/useAutorizedBrowserAPIs'
import { checkPermissions } from 'helpers/permissions'


ModalAutorizedBrowserAPI.propTypes = {
    service: PropTypes.string.isRequired,
    autorizedBrowserAPIs: PropTypes.array,
    refuseCallback: PropTypes.func
}

const isExpiredDate = (autorizedBrowserAPI) => {
    const now = new Date().getTime()
    return ((autorizedBrowserAPI.lastPrompt + autorizedBrowserAPI.promptTimeout) <= now)
}

function ModalAutorizedBrowserAPI(props) {    
    const {service, refuseCallback, agreedCallback, children } = props
    const { getAutorizedBrowserAPIs, setAutorizedBrowserAPIs } = useAutorizedBrowserAPIs()
    const [autorizedBrowserAPI, setAutorizedBrowserAPI] = useState(null)   
    const [modalOpen, setModalOpen] = useState(true)     

    useEffect(() => {
        setAutorizedBrowserAPI(getAutorizedBrowserAPIs(service))
    }, [])

    useEffect(() => {
        if(!autorizedBrowserAPI) return null  

        if(autorizedBrowserAPI.agreed || !isExpiredDate(autorizedBrowserAPI)) {       
            setModalOpen(false)
        }

    }, [autorizedBrowserAPI])
    
    if(!autorizedBrowserAPI) return null
    
    const handleRefuse = () => {        
        setAutorizedBrowserAPIs(autorizedBrowserAPI, false)       

        if(refuseCallback) refuseCallback()
        else setModalOpen(false)
    }

    const handleAccept = () => {
        checkPermissions[autorizedBrowserAPI.slug]().then(
            () => {
                setAutorizedBrowserAPIs(autorizedBrowserAPI, true)
                setModalOpen(false)
                if(agreedCallback) agreedCallback()
            },
            () => {
                setAutorizedBrowserAPIs(autorizedBrowserAPI, true)
                setModalOpen(false)
            }
        )       
    }  

    return (
        <>
            {modalOpen &&
                <Modal closeAction={handleRefuse}>
                    <Typography variant="h1">
                        {autorizedBrowserAPI.title}
                    </Typography>
                    <Box mt={3} mb={3}>
                        <Markdown source={autorizedBrowserAPI.text} />
                    </Box>
                    <Box mt={4} mb={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button
                                    type="button"
                                    className={clsx('large', 'icon')}
                                    color='secondary'
                                    onClick={handleAccept}
                                ><ThumbUpIcon />{_('app.agree')}</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    type="button"
                                    className={clsx('large', 'icon')}
                                    
                                    onClick={handleRefuse}
                                ><ThumbDownIcon />{_('app.refuse')}</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            }
            {!modalOpen && children}
        </>
    )
}

export default ModalAutorizedBrowserAPI

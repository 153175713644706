import { authConstants } from 'stores/auth/auth.constants'
import { userInfoConstants } from 'stores/userInfo/userInfo.constants'

const initialState = null

// all state changes manipulations happen here
export function userInfoStore(state = initialState, { type, payload }) {   
    switch (type) {
        case userInfoConstants.LIST_SUCCESS:
            return payload.data
        
        case userInfoConstants.UPDATE_SUCCESS:
            return payload.data

        case authConstants.LOGOUT_DEVICES_SUCCESS:
        case authConstants.LOGOUT_SUCCESS:            
            return null
        default:
            return state
    }
}

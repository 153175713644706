import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'

import { _ } from 'helpers/lang'
import { isOnline } from 'helpers/data'
import { validate } from './RegisterForm.helpers'
import { useStyles } from './RegisterForm.styles'
import Button from 'components/Button/Button'

RegisterForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object
}

RegisterForm.defaultProps = {
    initialValues: {
        first_name: '',
        last_name: '',
        password: '',
        password_confirmation: '',
        email: '',
        address: '',
        city: '',
        postal_code: '',
        phone: '',        
    }
}

function RegisterForm({ handleSubmit, initialValues }) {
    const classes = useStyles()

    const [disabled, setDisabled] = React.useState(false)

    return (
        <Box>

            {isOnline()
        
                ? (<Formik
                    initialValues={initialValues}
                    validate={validate}
                    onSubmit={handleSubmit}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                        } = props
                        
                        if(isSubmitting){
                            setTimeout(() => {
                                setDisabled(true)
                            }, 20)
                        }else{
                            setTimeout(() => {
                                setDisabled(false)
                            }, 1000)
                        }

                        return (
                            <Form className={classes.form}>                                    
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="text"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="first_name"
                                    label={_('field.first_name')}
                                    name="first_name"
                                    value={values.first_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.first_name && touched.first_name}
                                    helperText={errors.first_name && touched.first_name ? errors.first_name : undefined}
                                />

                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="text"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="last_name"
                                    label={_('field.last_name')}
                                    name="last_name"
                                    value={values.last_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.last_name && touched.last_name}
                                    helperText={errors.last_name && touched.last_name ? errors.last_name : undefined}
                                />

                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="email"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label={_('field.email')}
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.email && touched.email}
                                    helperText={errors.email && touched.email ? errors.email : undefined }
                                />

                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="password"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="password"
                                    label={_('field.password')}
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.password && touched.password}
                                    helperText={errors.password && touched.password ? errors.password : undefined}
                                />

                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="password"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="password_confirmation"
                                    label={_('field.password_confirmation')}
                                    name="password_confirmation"
                                    value={values.password_confirmation}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.password_confirmation && touched.password_confirmation}
                                    helperText={errors.password_confirmation && touched.password_confirmation ? errors.password_confirmation : undefined }
                                />

                                <Box mt={3} align="center">
                                    <Button
                                        type="submit"
                                        disabled={disabled}
                                        className='large'
                                    >{_('auth.createAccount')}</Button>
                                </Box>                                   
                            </Form>
                        )
                    }}
                </Formik>)

                : (<div>
                    <h2>{_('app.offline_title')}</h2>
                    <p>{_('app.offline_text')}</p>
                </div>)
            }
        </Box>
    )
}

export default RegisterForm
import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ReplayIcon from '@material-ui/icons/Replay'
import clsx from 'clsx'

import { _ } from 'helpers/lang'
import { useStyles } from './DestinationsStamp.styles'
import DestinationStamp from 'components/Destination/DestinationStamp/DestinationStamp'
import stampIcon from 'assets/svg/marker.svg'
import Button from 'components/Button/Button'
import RouteEnum from 'constants/RouteEnum'
import { useSettings } from 'hooks/useSettings'

export function DestinationsStamp({ destinations, stamps, completed, sended, modalAction, ...props}) {
    const classes = useStyles()
    const { getSetting } = useSettings()    

    let userDestinations = []
    destinations.forEach((destination) => {
        destination.completed = stamps.some(e => e.id === destination.id)
        userDestinations.push(destination)
    })

    userDestinations.sort(function(a, b) {
        var textA = a.name.toUpperCase()
        var textB = b.name.toUpperCase()
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
    })

    userDestinations.sort((a, b) => {
        return b.completed - a.completed
    })

    return (       
        <Box>
            <Box m={1.5} align="center">
                <Grid container
                >
                    <Grid item xs={12} align="center">
                        <Box className={classes.containerStampCount}>
                            <Grid container
                                spacing={1}
                            >
                                <Grid item xs={3} align="right">
                                    <img alt={_('app.begin')} src={stampIcon} />
                                </Grid>
                                <Grid item xs={9} align="left">
                                    <Typography variant="h1">
                                        {stamps.length} / {destinations.length}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {completed && 
                <Box mt={5} mb={6} align="center">
                    <Typography variant="h1" element="h2" className={classes.congrat} align="center">
                        {_('app.congratulations')}!
                    </Typography>
                    <Typography variant="h4" align="center">
                        {getSetting('destinationRoute_congratulations_content')}
                    </Typography>                    
                    {sended && <> 
                        <Box mt={2}>
                            <Typography variant="h4" component="p" align="center">
                                {getSetting('destinationRoute_reset_content')}<br />...
                            </Typography>
                        </Box>                            
                        <Box mt={2}>
                            <Button
                                type="button"
                                className={clsx(classes.btn, 'large', 'icon')}
                                onClick={modalAction}
                            >
                                <ReplayIcon />{_('app.reset_route')}
                            </Button>
                        </Box>                                                
                    </>}
                    {!sended && <Button to={RouteEnum.REWARD} className={clsx('large', classes.btnReward)}>{_('app.getReward')}</Button>}
                </Box>
            }            
            {userDestinations.map((destination, i) => (
                <Box 
                    key={i} 
                    mb={1.5} 
                    pt={2.5} 
                    pb={2.5}
                    pl={1}                  
                    pr={1}                  
                    className={clsx({completed:destination.completed}, classes.item)}
                >                    
                    <Grid 
                        container
                        alignItems="center"
                    >
                        <Grid item xs={3} align="center">
                            <DestinationStamp big alt={destination.name} destinationId={destination.id} />
                        </Grid>
                        <Grid item xs={8}>
                            <Typography component="h2" variant="subtitle1">
                                {destination.name}
                            </Typography>
                            <Box>
                                {destination.municipality}
                            </Box>
                        </Grid>
                    </Grid>                                    
                </Box>                                
            ))}
        </Box>
    )
}

export default DestinationsStamp

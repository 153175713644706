import { createSelector } from 'reselect'

export class userInfosSelector {
    static selectUserInfo(userInfo) {
        return userInfo
    }

    static selectUserInfoById(userInfo, id) {
        return userInfo.find((d) => d.id === id)
    }
}

export const selectUserInfo = createSelector(
    (state) => state.userInfo,
    userInfosSelector.selectUserInfo
)

export const selectUserInfoById = createSelector(
    (state) => state.userInfo,
    (state, id) => id,
    userInfosSelector.selectUserInfoById
)

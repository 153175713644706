import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        textAlign: 'center',
    },
    paper: {
        paddingTop: 'calc(50px + 10vh)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            paddingTop: 'calc(75px + 10vh)',
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 'calc(100px + 10vh)',
        },
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    buttonlist: {
        margin: theme.spacing(3, 0, 3),
    },
    link: {
        color: theme.palette.primary[900],
        fontWeight: '600',
    },
}))

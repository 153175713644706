export const removeEmpty = obj => {
    Object.keys(obj).forEach(key =>
        (
            obj[key] === '' ||
            obj[key] === ' ' ||
            obj[key] == null
        ) && delete obj[key])
    return obj
}

export const flattenChildren = data => {
    // copy data without children property
    let result = [...data]

    data.forEach(d => {
        if (d.children) {
            result = [...result, ...flattenChildren(d.children)]
        }
    })

    return result
}

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

export const formatPhone = string => string.replace(/-|\(|\)|\.|\s/g,'') // removes - . ( ) and spaces

import { tagConstants } from 'stores/tag/tag.constants'

const initialState = []

// all state changes manipulations happen here
export function tagStore(state = initialState, { type, payload }) {
    switch (type) {
        case tagConstants.LIST_SUCCESS:
            return payload.data

        default:
            return state
    }
}

import React from 'react'
import Box from '@material-ui/core/Box'

import { useStyles } from './DestinationsList.styles'
import DestinationDetail from 'components/Destination/DestinationDetail/DestinationDetail'

export function DestinationsList({ destinations, allAddress }, props) {
    const classes = useStyles()

    return (       
        <Box pb={(allAddress ? 10 : 0)} mt={1}>            
            {destinations.map((destination, i) => (
                <Box 
                    key={i} 
                    mb={3}
                    pt={2.5} 
                    pb={2.5}
                    pl={1}                  
                    pr={1}                  
                    className={classes.item}
                >                    
                    <DestinationDetail allAddress={!allAddress} closed={true} destination={destination} />                    
                </Box>                                
            ))}
        </Box>        
    )
}

export default DestinationsList

import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { isOnline } from 'helpers/data'
import { useStyles } from './Image.styles'

Image.propTypes = {
    alt: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    className: PropTypes.string,
}

export function Image(props) {
    const classes = useStyles()

    if (!isOnline()) return null
    const classNames = clsx(classes.root, props.className)
    
    return (
        <img
            className={classNames}
            alt={props.alt}
            src={props.src}
            {...props}
        />
    )
}

export default Image

import { removeEmpty } from 'utils/functions'
import { email } from 'utils/validation'

export const validate = values => {
    const errors = {
        email: email(values.email)
    }
    
    return removeEmpty(errors)
}
